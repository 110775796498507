import { CareersHero } from "assets/images";
import styled from "styled-components";
import { mixins } from "theme";

export const CareersHeaderContainer = styled.header`
  padding-top: 0 !important;
  position: relative;
  width: 100%;
  /* background-image: url(${CareersHero}); */
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .hero-img {
    width: 100%;
    min-width: 100%;
    min-height: 400px;
  }
  .content-wrapper {
    position: absolute;
    width: 100%;
    bottom: 22%;
    .header-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h1 {
        font-size: 70px !important;
      }
      h5 {
        margin-bottom: 0;
        word-break: keep-all;
      }
    }
  }
  @media (max-width: 1000px) {
    .hero-img {
      min-width: unset;
      min-height: 400px;
      max-height: 400px;
    }
    .content-wrapper {
      .header-content {
        h1 {
          font-size: 45px !important;
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    justify-content: center;
    .hero-img {
      width: 100%;
      min-height: unset;
      max-height: unset;
    }
    .content-wrapper {
      bottom: 55%;
      .header-content {
        align-items: flex-start;
        h1 {
          font-size: 32px !important;
        }
        h5 {
          font-size: 20px !important;
          width: 80%;
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
