import { GetTheApp, GetTheAppMobile } from "assets/images/restaurants";
import styled from "styled-components";
import { mixins } from "theme";

export const DownloadContainer = styled.div`
  width: 100%;
  padding: 150px 0 300px;
  .join-overlay {
    display: flex;
    justify-content: center;
    .join-content-wrapper {
      width: 1580px;
      height: 430px;
      background: url(${GetTheApp});
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center;
      display: flex;
      justify-content: flex-end;
      .join-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: 40%;
        h5 {
          font-size: 24px !important;
          margin-bottom: 35px;
          line-height: 1.5;
          width: 90%;
        }
        .btn-join {
          width: 315px;
          height: 80px;
          border-radius: 100px;
          font-size: 26px !important;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.62px;
          margin-bottom: 40px;
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    padding: 30px 0 120px;
    .join-overlay {
      display: flex;
      justify-content: center;
      .join-content-wrapper {
        flex-direction: column;
        width: 100%;
        height: unset;
        border-radius: 63px;
        background: linear-gradient(
          156deg,
          rgba(255, 127, 127, 0.53) -118%,
          #ff7f7f -60%,
          #fe7979 -17%,
          #ee2c2c 43%,
          #ed2626 100%
        );
        .join-content {
          width: 100%;
          align-items: center;
          padding: 46px 0 76px;
          h5 {
            font-size: 18px !important;
            margin-bottom: 45px;
            text-align: center;
            line-height: 1.56;
            max-width: 83%;
          }
          .btn-join {
            margin-bottom: 0;
          }
        }
        .join-content-right {
          width: 100%;
          height: fit-content;
          /* background: url(${GetTheAppMobile});
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-position: center; */
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
