import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import GlobalStyle from "theme/globalStyle";
import "assets/css/fontawesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import MyRoute from "service/MyRoute";
import Customers from "containers/Customers";
import Restaurants from "containers/Restaurants";
import Blog from "containers/Blog";
import BlogDetail from "containers/BlogDetail";
import Careers from "containers/Careers";
import CareerDetail from "containers/CareerDetail";
import Contact from "containers/Contact";
import Privacy from "containers/Privacy";

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to="/home/customers" />}
          />
          <MyRoute exact path="/home/customers" component={Customers} />
          <MyRoute exact path="/home/restaurants" component={Restaurants} />
          {/*<MyRoute exact path="/blog" component={Blog} />*/}
          {/*<MyRoute exact path="/blog-detail/:id" component={BlogDetail} />*/}
          <MyRoute exact path="/careers" component={Careers} />
          <MyRoute exact path="/careers/:id" component={CareerDetail} />
          <MyRoute exact path="/contact" component={Contact} />
          <MyRoute exact path="/privacy" component={Privacy} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
