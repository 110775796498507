import { Col, Row } from "antd";
import React from "react";
import { Container, Image } from "react-bootstrap";
import { HowToFeastttContainer } from "./style";
import { IconFeasttt } from "assets/images/icons";
import {
  RestaurantHow1,
  RestaurantHow2,
  RestaurantHow3,
} from "assets/images/restaurants";

function HowToFeasttt() {
  return (
    <HowToFeastttContainer>
      <Container className="d-flex flex-column align-items-center" fluid>
        <Image src={IconFeasttt} className="img-howto mx-auto mb-2" />
        <h1 className="how-to-feasttt">How to Feasttt.</h1>
        <div className="cards-wrapper">
          <Row justify="center" gutter={[{sm: 0, md: 100}, 40]}>
            <Col md={8} xs={24}>
              <div className="how-card">
                <h5>
                  Customer arrives and checks in at your restaurant using a
                  code.
                </h5>
                <Image src={RestaurantHow1} />
              </div>
            </Col>
            <Col md={8} xs={24}>
              <div className="how-card">
                <h5>
                  Customer can invite guests, order, request refills, and more
                  with a tap.
                </h5>
                <Image src={RestaurantHow2} />
              </div>
            </Col>
            <Col md={8} xs={24}>
              <div className="how-card">
                <h5>
                  Customer can pay, split the bill, and rate their feast at the
                  end.
                </h5>
                <Image src={RestaurantHow3} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </HowToFeastttContainer>
  );
}

export default HowToFeasttt;
