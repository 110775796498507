import styled from "styled-components";
import { colors, mixins } from "theme";

export const HowToFeastttContainer = styled.div`
  width: 100%;
  padding: 150px 0 100px;
  .container-fluid {
    .img-howto {
      width: 73px;
    }
    h1 {
      font-size: 100px !important;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -2.4px;
      text-align: center;
      color: ${colors.grayLight};
      margin-bottom: 100px;
    }
    .cards-wrapper {
      max-width: 1580px;
      .how-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        h5 {
          text-align: center;
          font-weight: bold;
          margin-bottom: 60px;
          line-height: 1.42;
          letter-spacing: -0.76px;
        }
        img {
          width: 100%;
          margin-bottom: 60px;
        }
        .how-card-description {
          color: ${colors.gray};
          opacity: 0.6;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    padding: 100px 0;
    .container-fluid {
      .img-howto {
        width: 46px;
      }
      h1 {
        font-size: 36px !important;
        margin-bottom: 60px;
        letter-spacing: -0.86px;
      }
      .cards-wrapper {
        .how-card {
          h5 {
            font-size: 20px !important;
            margin-bottom: 25px;
            line-height: 1.5;
            letter-spacing: normal;
          }
          img {
            margin-bottom: 0px;
          }
          .how-card-description {
            font-size: 14px !important;
            margin-bottom: 30px;
            line-height: 1.86;
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
