import styled from "styled-components";
import { colors, mixins } from "theme";

export const OpenPositionsContainer = styled.div`
  width: 100%;
  padding: 200px 0;
  .open-left {
    h1 {
      font-size: 90px !important;
      color: ${colors.red};
      line-height: 1.11;
      letter-spacing: -2.16px;
    }
  }
  .open-right {
    .position-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 26px 43px 23px;
      border-bottom: solid 1px #c5c5c5;
      &:last-child {
        border-bottom: none;
      }
      .position-right {
        h6 {
          color: ${colors.red};
          font-weight: bold;
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    padding: 50px 0;
    .open-left {
      h1 {
        font-size: 45px !important;
      }
    }
    .open-right {
      .position-item {
        padding: 26px 0px 23px;
      }
      h6 {
        font-size: 18px !important;
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
