import React from "react";
import { MyInputContainer } from "./style";

function MyInput({
  className = "",
  type = "text",
  placeholder = "",
  onChange = () => {},
}) {
  return (
    <MyInputContainer
      type={type}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}

export default MyInput;
