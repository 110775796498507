import styled from "styled-components";
import { mixins } from "theme";

export const PageLayoutContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh);
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  .page-content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    header {
      padding-top: 80px;
    }
  }
  @media (max-width: ${mixins.md}px) {
    .page-content {
      header {
        /* padding-top: ${props => props.homepage ? '146px' : '100px'}; */
        padding-top: 80px;
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
