import styled from "styled-components";
import { colors, mixins } from "theme";

export const BlogDetailContainer = styled.header`
  position: relative;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  .detail-overlay {
    padding-top: 50px;
    padding-bottom: 100px;
    .container {
      .detail-navigation {
        color: ${colors.gray};
        font-size: 20px !important;
        margin-bottom: 30px;
        a,
        i {
          text-decoration: none;
          color: #989898;
        }
        i {
          margin: 0 5px;
        }
      }
      .detail-navigation-mobile {
        display: none;
      }
      .item-content {
        h1 {
          font-size: 60px !important;
        }
        h1.item-title {
          margin-bottom: 60px;
          margin-top: 80px;
        }
        h2 {
          font-size: 36px !important;
        }
        p {
          font-size: 24px !important;
        }
        ul li {
          font-size: 24px !important;
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    .detail-overlay {
      padding-top: 30px;
      padding-bottom: 60px;
      .container {
        &.img-container {
          padding: 0;
        }
        .detail-navigation {
          display: none;
        }
        .detail-navigation-mobile {
          display: initial;
          padding-left: 25px;
          a {
            font-size: 16px !important;
            color: #989898;
          }
        }
        .item-content {
          margin-top: 20px;
          h1.item-title {
            margin-bottom: 40px;
            margin-top: 40px;
            font-size: 36px !important;
          }
          p, ul li {
            font-size: 19px !important;
          }
          h2 {
            font-size: 30px !important;
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;

export const RecommendsContainer = styled.div`
  background: #f6f6f6;
  width: 100%;
  height: 772px;
  display: flex;
  align-items: center;
  h3.recommend-title {
    color: #989898;
    font-size: 32px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: -0.77px;
    margin-bottom: 30px;
  }
  @media (max-width: ${mixins.md}px) {
    height: unset;
    padding: 60px 0;
  }
`;
