/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Skeleton } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { BlogContainer, FeaturedContainer, BlogCardContainer } from "./style";

function Blog() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);

  const getBlogs = () => {
    setIsLoading(true);
    fetch(`${window.location.origin}/posts.json`).then((response) => {
      response.json().then((posts) => {
        setBlogs(posts);
        setTimeout(() => setIsLoading(false), 1000);
      });
    });
  };

  useEffect(() => {
    getBlogs();
  }, [history.location.pathname]);

  return (
    <BlogContainer>
      <div className="blog-overlay w-100">
        <Container className="featured">
          {isLoading ? (
            <Fragment>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Fragment>
          ) : (
            <Row>
              <FeaturedContainer
                className="featured-blog w-100"
                bg={blogs[0].featured_media_url}
              >
                <div className="featured-content">
                  <h6 className="featured-mark">FEATURED</h6>
                  <h1 className="featured-description">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blogs[0]?.title?.rendered,
                      }}
                    />
                  </h1>
                </div>
              </FeaturedContainer>
            </Row>
          )}
        </Container>
        <Container className="posts-container">
          {isLoading ? (
            <Fragment>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Fragment>
          ) : (
            <Fragment>
              <h3 className="all-posts">All posts</h3>
              <Row gutter={[{sm: 0, md: 80}, 80]}>
                {blogs.map((_blog, _i) => (
                  <Col key={_i} lg={8} md={12} sm={24} xs={24}>
                    <Link to={`/blog-detail/${_blog.id}`}>
                      <BlogCardContainer
                        className="blog-card"
                        blogImg={_blog.featured_media_url}
                      >
                        <div className="blog-card-img" />
                        <h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: _blog?.title?.rendered,
                            }}
                          />
                        </h3>
                      </BlogCardContainer>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Fragment>
          )}
        </Container>
      </div>
    </BlogContainer>
  );
}

export default Blog;
