import styled from "styled-components";
import { colors, mixins } from "theme";

export const CustomerHeaderContainer = styled.header`
  width: 100%;
  height: 861px;
  background-color: ${colors.pink6};
  display: flex;
  align-items: center;
  .container {
    height: 100%;
    .btn-get-the-app-sm {
      width: 100%;
      height: 80px;
      font-size: 26px;
    }
    .header-left {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .header-description {
        margin-bottom: 50px;
        background-image: linear-gradient(113deg, #ed2626, #ff7878);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        span {
          font-weight: normal;
        }
      }
      .btn-get-the-app {
        width: 315px;
        height: 80px;
        font-size: 26px;
      }
    }
    .header-right {
      display: flex;
      align-items: flex-end;
      .header-image {
        /* width: 332px; */
        height: 80%;
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    max-height: unset;
    flex-direction: column;
    height: fit-content;
    .container {
      padding-top: 48px;
      .header-left {
        align-items: center;
        h3 {
          font-size: 20px !important;
          font-weight: 500;
        }
        .header-description {
          font-size: 40px !important;
          margin-bottom: 50px;
          text-align: center;
        }
      }
      .header-right {
        margin-top: 53px;
        .header-image {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
