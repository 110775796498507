/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import Footer from "./Footer";
import { PageLayoutContainer } from "./style";
import Topbar from "./Topbar";

function PageLayout({ children }) {
  const history = useHistory();
  const scrollRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = (e) => {
    setScrolled(e.scrollTop > 100);
  };

  useEffect(() => {
    scrollRef.current.scrollToTop();
  }, [history.location.pathname]);
  return (
    <Scrollbars
      ref={scrollRef}
      autoHide
      style={{ flex: 1 }}
      onScrollFrame={handleScroll}
    >
      <PageLayoutContainer homepage={history.location.pathname.includes('home')}>
        <Topbar scrolled={scrolled} />
        <div className="page-content">{children}</div>
        <Footer />
      </PageLayoutContainer>
    </Scrollbars>
  );
}

export default PageLayout;
