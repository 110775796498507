import styled from "styled-components";
import { colors, mixins } from "theme";

export const TopbarContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 80px;
  background: ${(props) => (props.bg === "white" ? "#fff" : "transparent")};
  align-items: flex-start;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  z-index: 20;
  box-shadow: ${(props) =>
    props.bg === "white" ? "0 2px 4px 0 rgba(0, 0, 0, 0.1)" : "unset"};
  .container {
    .navbar-wrapper {
      display: flex;
      justify-content: space-between;
      min-height: 80px;
      align-items: center;
      .logo-img {
        display: flex;
        align-items: center;
        img {
          width: 170px;
        }
      }
      .navbar-link {
        font-size: 16px !important;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-left: 40px;
        &:hover {
          color: #000;
        }
      }
      .btns-switch {
        height: 50px;
        width: 300px;
        display: flex;
        padding: 6px;
        border-radius: 25px;
        background-color: ${(props) =>
          props.bg === "white" ? "rgba(237, 38, 38, 0.1)" : colors.white};
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        .navbar-link {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 38px;
          font-size: 14px !important;
          color: ${colors.red};
          &.active {
            color: ${colors.white};
            border-radius: 25px;
            background-color: ${colors.red};
          }
        }
      }
    }
    .navbar-wrapper-sm {
      position: relative;
      .icon-hamburger {
        position: absolute;
      }
      .ant-drawer {
        .ant-drawer-content-wrapper {
          .ant-drawer-content {
            .ant-drawer-wrapper-body {
              .ant-drawer-body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                background-color: ${colors.pink6};
                padding: 40px 0;
                @media (max-height: 765px) {
                  padding: 40px 0 10px;
                }
                .navbar-sm-content {
                  width: 100%;
                  margin-top: 25px;
                  .sm-link {
                    height: 82px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .link-icon {
                      width: 100px;
                    }
                    .link-title {
                      flex: 1;
                      height: 100%;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      border-bottom: solid 1px #e4dada;
                      h5 {
                        font-size: 20px;
                        font-weight: 600;
                        margin-bottom: 0;
                      }
                    }
                  }
                }
                .btns-switch-sm {
                  height: 50px;
                  width: 300px;
                  display: flex;
                  margin-top: 40px;
                  @media (max-height: 765px) {
                    margin-top: 20px;
                  }
                  padding: 6px;
                  border-radius: 25px;
                  background-color: ${(props) =>
                    props.bg === "white"
                      ? "rgba(237, 38, 38, 0.1)"
                      : colors.white};
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                  .navbar-link {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 38px;
                    font-size: 14px !important;
                    color: ${colors.red};
                    margin-bottom: 0;
                    &.active {
                      color: ${colors.white};
                      border-radius: 25px;
                      background-color: ${colors.red};
                    }
                  }
                }
                .close-icon {
                  width: 60px;
                  height: 60px;
                  min-height: 60px;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                  border: solid 1px rgba(255, 255, 255, 0.12);
                  background-color: #d3d3d3;
                  border-radius: 50%;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    min-height: 60px;
  }
`;
