import styled from "styled-components";
import { colors, mixins } from "theme";

const leftPadding = 120;

export const SectionSliderContainer = styled.div`
  width: 100%;
  padding: 200px 0 150px;
  .slider-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    .carousel {
      width: 1580px;
      .carousel-indicators {
        bottom: unset;
        top: 120px;
        margin-left: ${leftPadding}px;
        justify-content: flex-start;
        align-items: center;
        li {
          width: 12px;
          height: 12px;
          margin: 0 10px;
          box-shadow: 3px 5px 31px 0 rgb(245 111 111 / 80%);
          background-color: ${(props) =>
            props.activeIndex === 0 ? colors.white : colors.red};
          border-radius: 50%;
          border-top: unset;
          border-bottom: unset;
          transition: all 0.5s ease;
          &:first-of-type {
            margin-left: 0;
          }
          &.active {
            width: 23px;
            height: 23px;
          }
        }
      }
      .carousel-control-prev,
      .carousel-control-next {
        width: fit-content;
        height: fit-content;
        top: unset;
        bottom: 120px;
        .icon-arrow {
          width: 50px;
          height: 50px;
          background: white;
          border-radius: 50px;
          color: red;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 3px 5px 35px 0 rgba(245, 111, 111, 0.4);
        }
      }
      .carousel-control-prev {
        left: ${leftPadding}px;
      }
      .carousel-control-next {
        right: unset;
        left: ${leftPadding + 80}px;
      }
      .carousel-inner {
        .carousel-item {
          .slide-item {
            position: relative;
            height: 700px;
            padding: 50px 0;
            h1 {
              font-size: 80px !important;
            }
            .img-wrapper {
              position: absolute;
              right: 0;
              top: 0;
              width: 50%;
              display: flex;
              justify-content: flex-end;
              img {
                height: 700px;
              }
            }
            .slide-bg {
              border-radius: 50px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              /* box-shadow: 13px 15px 45px 0 rgba(245, 111, 111, 0.18); */
              .slide-left {
                width: 60%;
                padding-left: ${leftPadding}px;
                h1 {
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.13;
                  letter-spacing: -1.92px;
                  span {
                    font-weight: normal;
                  }
                }
                h6 {
                  font-style: normal;
                  line-height: 1.6;
                  &.subtitle {
                    color: ${colors.red};
                  }
                }
              }
              .slide-right {
                width: 40%;
                position: relative;
                display: flex;
                align-items: center;
              }
            }
            &.slide1 {
              .slide-bg {
                background: linear-gradient(
                  90deg,
                  rgba(237, 38, 38, 1) 0%,
                  rgba(255, 127, 127, 1) 100%
                );
                .slide-left {
                  h6 {
                    color: ${colors.white};
                  }
                }
              }
            }
            &.slide2 {
              .img-wrapper {
                justify-content: center;
              }
              .slide-bg {
                background: #fff3f3;
                .slide-left {
                  color: ${colors.dark5};
                }
              }
            }
            &.slide3 {
              .slide-bg {
                background: #ededed;
                .slide-left {
                  color: ${colors.dark5};
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) and (max-width: ${mixins.md}px) {
    padding: 60px 0;
    .slider-sm-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .carousel {
        width: 100%;
        .carousel-indicators {
          align-items: center;
          margin-bottom: 3em;
          li {
            width: 9px;
            height: 9px;
            margin-right: 8px;
            margin-left: 8px;
            border-top: 0;
            border-bottom: 0;
            border-radius: 50px;
            background-color: ${(props) =>
              props.activeIndex === 0 ? colors.white : colors.red};
            &.active {
              width: 17px;
              height: 17px;
            }
            &:first-of-type {
              /* margin-left: 10px; */
            }
          }
        }
        .carousel-control-prev,
        .carousel-control-next {
          width: fit-content;
          height: fit-content;
          top: unset;
          bottom: 120px;
          .icon-arrow {
            width: 35px;
            height: 35px;
            background: white;
            border-radius: 50px;
            color: red;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 4px 15px 0 rgba(245, 111, 111, 0.2);
            svg {
              width: 10px;
            }
          }
        }
        .carousel-control-prev {
          left: calc(50% - 45px);
          bottom: 87px;
        }
        .carousel-control-next {
          left: calc(50% + 10px);
          bottom: 87px;
        }
        .carousel-inner {
          border-radius: 50px;
          .carousel-item {
            .slide-item {
              height: 780px;
              padding: 40px 15px;
              box-shadow: 13px 15px 45px 0 rgba(245, 111, 111, 0.18);
              h6 {
                color: white;
                opacity: 0.7;
                text-align: center;
                margin-bottom: 30px;
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
              }
              h3 {
                font-size: 32px !important;
                font-weight: bold;
                text-align: center;
                span {
                  font-weight: normal;
                }
              }
              p {
                color: white;
                opacity: 0.7;
                font-weight: bold;
                line-height: 1.5;
                text-align: center;
              }
              .img-wrapper {
                top: unset;
                bottom: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 5px;
                img {
                  height: 348px;
                  width: auto;
                }
              }
              &.slide1 {
                background-image: linear-gradient(to right, #ed2727, #fba1a1);
                .slide-bg {
                  background: linear-gradient(
                    154deg,
                    rgba(255, 127, 127, 0.53) 3%,
                    #ff7f7f 24%,
                    #fe7979 40%,
                    #ee2c2c 63%,
                    #ed2626 84%
                  );
                  .slide-left {
                    h6 {
                      color: ${colors.white};
                    }
                  }
                }
              }
              &.slide2 {
                background: ${colors.pink6};
                h6 {
                  color: ${colors.red};
                }
                p {
                  color: ${colors.dark5};
                }
              }
              &.slide3 {
                background: ${colors.pink6};
                h6 {
                  color: ${colors.red};
                }
                p {
                  color: ${colors.dark5};
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
