/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ContactContainer } from "./style";
import ContactHeader from "./ContactHeader";
import ContactInfo from "./ContactInfo";

function Contact() {
  return (
    <ContactContainer>
      <ContactHeader />
      <ContactInfo />
    </ContactContainer>
  );
}

export default Contact;
