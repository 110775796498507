/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import { RestaurantHeaderMobile } from "assets/images/restaurants";
import React from "react";
import { Button, Container, Image } from "react-bootstrap";
import { RestaurantHeaderContainer } from "./style";

function RestaurantHeader({ onClickLearn = () => {} }) {
  return (
    <RestaurantHeaderContainer className="animate__animated animate__fadeIn">
      <div className="header-wrapper">
        <Container className="my-auto">
          <Row>
            <Col lg={10} xs={24}>
              <div className="header-left">
                <h1 className="text-red header-description">
                  Boost your
                  <br /> average order
                  <br /> <span>with</span> Feasttt.
                </h1>
                <Button
                  className="btn-get-the-app btn-red"
                  onClick={onClickLearn}
                >
                  Learn how
                </Button>
              </div>
            </Col>
            <Col lg={14} xs={24} className="d-md-none">
              <div className="img-wrapper">
                <Image src={RestaurantHeaderMobile} width="100%" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </RestaurantHeaderContainer>
  );
}

export default RestaurantHeader;
