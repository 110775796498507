import { GetTheAppMobile } from "assets/images/restaurants";
import { BgModalHeader, BgModalThankyou } from "assets/images/customer";
import { Modal, Form } from "antd";
import React, { useRef, useState } from "react";
import { Button, Container, Image } from "react-bootstrap";
import { DownloadContainer } from "./style";
import MyInput from "components/Elements/MyInput";
import MyPhoneInput from "components/Elements/MyPhoneInput";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchasitekey, showNotification } from "service";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import { IconRestaurant, IconFoodie } from "assets/images/icons";

const userTypes = [
  {
    value: 1,
    img: IconFoodie,
    title: "Foodie",
  },
  {
    value: 2,
    img: IconRestaurant,
    title: "Restaurant",
  },
];
function Download() {
  const recaptchaRef = useRef();
  const [form] = Form.useForm();
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [showModalThankyou, setShowModalThankyou] = useState(false);
  const [valReCaptcha, setValReCaptcha] = useState("");
  const [activeUser, setActiveUser] = useState(1);
  const [url, setUrl] = useState();
  const [submitting, setSubmitting] = useState(false);

  const onVerifyCaptcha = (value) => {
    setValReCaptcha(value);
    console.log({ value });
  };

  const onFinish = async (values) => {
    if (!valReCaptcha) {
      showNotification("error", "Please, verify captcha.");
      return;
    }
    setSubmitting(true);
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("name", values.name);
    formData.append("phone", values.phone);
    formData.append("utype", activeUser);
    formData.append("captchaResponse", valReCaptcha);
    const result = await axios.post(
      "https://code-room.com/feastdocs/getpdf.php",
      formData
    );
    if (result.data.status === "success") {
      window.open(result.data.url, "_blank");
      setUrl(result.data.url);
      recaptchaRef.current.reset();
      form.resetFields();
      setShowModalDownload(false);
      setShowModalThankyou(true);
    } else {
      showNotification("error", "Something went wrong");
    }
    setSubmitting(false);
  };
  return (
    <DownloadContainer>
      <Container className="d-flex flex-column" fluid>
        <div className="join-overlay">
          <div className="join-content-wrapper">
            <div className="join-content">
              <h5 className="join-title text-white">
                <b>Download our white paper</b> to take a deep dive into the
                Feasttt experience and see how we help restaurants increase
                revenue.
              </h5>
              <Button
                className="btn-join btn-white"
                onClick={() => setShowModalDownload(true)}
              >
                Download
              </Button>
            </div>
            <div className="join-content-right d-md-none">
              <Image src={GetTheAppMobile} className="d-md-none" width="100%" />
            </div>
          </div>
        </div>
      </Container>
      <Modal
        wrapClassName="modal-download"
        title={null}
        footer={null}
        visible={showModalDownload}
        centered
        closable={false}
        maskClosable={true}
        onCancel={() => setShowModalDownload(false)}
      >
        <div className="modal-header-img">
          <Image src={BgModalHeader} width="100%" />
        </div>
        <div className="download-form">
          <h3 className="download-title text-center">Get the white paper.</h3>
          <div className="user-type">
            <label>Which best describes you?</label>
            <div className="user-cards-wrapper d-flex">
              {userTypes.map((_type, _i) => (
                <div
                  key={_i}
                  className={`user-card cursor ${_i === 0 ? "mr-3" : ""} ${
                    activeUser === _type.value && "selected"
                  }`}
                  onClick={() => setActiveUser(_type.value)}
                >
                  <Image src={_type.img} width={45} />
                  <p>{_type.title}</p>
                </div>
              ))}
            </div>
          </div>
          <Form
            className="header-right"
            layout="vertical"
            form={form}
            initialValues={{
              phone: "",
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="Full Name"
              rules={[
                {
                  required: true,
                  message: "Required Field!",
                },
              ]}
              hasFeedback
            >
              <MyInput placeholder="Share your full name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Required Field!",
                },
                {
                  type: "email",
                  message: "Invalid Email!",
                },
              ]}
              hasFeedback
            >
              <MyInput type="email" placeholder="Enter your e-mail" />
            </Form.Item>
            <Form.Item
              name="phone"
              label={
                <div className="w-100 d-flex justify-content-between">
                  <span>Phone Number</span>
                  <span className="optional">Optional</span>
                </div>
              }
              // rules={[
              //   {
              //     required: true,
              //     message: "Required Field!",
              //   },
              // ]}
              hasFeedback
            >
              <MyPhoneInput type="form" placeholder="Enter your phone number" />
            </Form.Item>
            <div className="recaptcha-wrapper">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={recaptchasitekey}
                style={{ margin: "0px auto" }}
                onChange={onVerifyCaptcha}
                onExpired={() => setValReCaptcha("")}
              />
            </div>
            <div className="btns-wrapper">
              <Form.Item>
                <Button
                  type="submit"
                  className="btn-red btn-submit"
                  disabled={submitting}
                >
                  Continue
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="btn-gray btn-cancel"
                  onClick={() => setShowModalDownload(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
            <div
              className="d-md-none d-flex icon-close-modal mx-auto"
              onClick={() => setShowModalDownload(false)}
            >
              <FaTimes size={18} />
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        wrapClassName="modal-thankyou"
        title={null}
        footer={null}
        visible={showModalThankyou}
        centered
        closable={false}
        onCancel={() => setShowModalThankyou(false)}
      >
        <div className="modal-header-img">
          <Image src={BgModalThankyou} width="100%" />
        </div>
        <div className="download-form">
          <h3 className="download-title text-center">Thank you!</h3>
          <p className="download-description text-center">
            Your download will begin shortly. Please click “Allow” if prompted
            by your browser.
          </p>
          <p className="down-again">
            If your download does not start, please click below:
          </p>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Button className="btn-red btn-submit">Download</Button>
          </a>
          <Button
            className="btn-gray btn-submit"
            onClick={() => setShowModalThankyou(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </DownloadContainer>
  );
}

export default Download;
