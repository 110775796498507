/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Skeleton, Space } from "antd";
import { BlogCardContainer } from "containers/Blog/style";
import React, { Fragment, useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { FaFacebook, FaLink, FaPinterest, FaTwitter } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { colors } from "theme";
import { BlogDetailContainer, RecommendsContainer } from "./style";

function BlogDetail() {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [recommends, setRecommends] = useState([]);
  const [detail, setDetail] = useState({});

  const getBlogDetail = () => {
    setIsLoading(true);
    fetch(`${window.location.origin}/posts.json`).then((response) => {
      response.json().then((posts) => {
        setRecommends(posts.filter((_post, _i) => _i < 3));
        setDetail(posts.find((_post) => _post.id == id));
        setTimeout(() => setIsLoading(false), 1000);
      });
    });
  };

  useEffect(() => {
    getBlogDetail();
  }, [history.location.pathname]);

  return (
    <Fragment>
      <BlogDetailContainer>
        <div className="detail-overlay w-100">
          {isLoading ? (
            <Container>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Container>
          ) : (
            <Fragment>
              <Container className="img-container">
                <div className="detail-navigation">
                  <Link to="/blog">Blog</Link>{" "}
                  <i className="fas fa-angle-right" /> {detail.title.rendered}
                </div>
                <div className="detail-navigation-mobile">
                  <Link to="/blog">
                    <i className="fas fa-angle-left" /> Back to the Blog
                  </Link>
                </div>
                <Image
                  src={detail.featured_media_url}
                  className="mt-3"
                  width="100%"
                />
              </Container>
              <Container>
                <div className="item-content">
                  <h1 className="item-title">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: detail?.title?.rendered,
                      }}
                    />
                  </h1>
                  <div
                    className="main-content-body"
                    dangerouslySetInnerHTML={{
                      __html: detail?.content?.rendered,
                    }}
                  />
                  <Space size={30} className="group-social">
                    <FaTwitter
                      size={24}
                      color={colors.dark5}
                      className="icon-social"
                    />
                    <FaFacebook
                      size={24}
                      color={colors.dark5}
                      className="icon-social"
                    />
                    <FaPinterest
                      size={24}
                      color={colors.dark5}
                      className="icon-social"
                    />
                    <FaLink
                      size={24}
                      color={colors.dark5}
                      className="icon-social"
                    />
                  </Space>
                </div>
              </Container>
            </Fragment>
          )}
        </div>
      </BlogDetailContainer>
      <RecommendsContainer className="recommend-section">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Container>
            <h3 className="recommend-title">Recommended for you.</h3>
            <Row gutter={80}>
              {recommends.map((_blog, _i) => (
                <Col key={_i} md={8}>
                  <Link to={`/blog-detail/${_blog.id}`}>
                    <BlogCardContainer
                      className="blog-card"
                      blogImg={_blog.featured_media_url}
                    >
                      <div className="blog-card-img" />
                      <h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: _blog?.title?.rendered,
                          }}
                        />
                      </h3>
                    </BlogCardContainer>
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        )}
      </RecommendsContainer>
    </Fragment>
  );
}

export default BlogDetail;
