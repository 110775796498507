import { BgRestaurantHeader } from "assets/images/restaurants";
import styled from "styled-components";
import { colors, mixins } from "theme";

export const RestaurantHeaderContainer = styled.header`
  width: 100%;
  min-height: 864px;
  background-color: ${colors.pink6};
  background-image: url(${BgRestaurantHeader});
  background-position: right;
  background-size: auto 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .header-wrapper {
    position: relative;
    flex: 1;
    width: 100%;
    display: flex;
    .container {
      .header-left {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .header-description {
          font-size: 60px !important;
          margin-bottom: 50px;
          background-image: linear-gradient(119deg, #ed2626, #ff7878 100%);
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: -1.44px;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          span {
            font-weight: normal;
          }
        }
        .btn-get-the-app {
          width: 315px;
          height: 80px;
          font-size: 26px !important;
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    padding-bottom: 0px;
    min-height: unset;
    background-image: unset;
    .header-wrapper {
      flex-direction: column;
      padding-top: 35px;
      .container {
        padding: 0;
        .header-left {
          padding: 50px 15px 0;
          .header-description {
            font-size: 40px !important;
            text-align: center;
            margin-bottom: 43px;
            margin-left: auto;
            margin-right: auto;
          }
          .btn-get-the-app {
            width: 100%;
          }
        }
        .img-wrapper {
          margin-top: 50px;
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
