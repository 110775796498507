import styled from "styled-components";
import { colors, fonts, mixins } from "theme";

export const FooterContainer = styled.div`
  width: 100%;
  height: 234px;
  background: ${colors.grayDark};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  /* border-radius: 40px 40px 0 0; */
  .footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.links {
      margin-bottom: 40px;
    }
    .navbar-link {
      color: ${colors.white5};
      font-size: 16px !important;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      &:hover {
        color: ${colors.white};
      }
    }
    p {
      color: ${colors.white5};
      font-family: ${fonts.nunito};
      font-size: 16px !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .group-link {
      .navbar-link {
        margin-right: 40px;
      }
    }
    .btns-switch {
      height: 50px;
      width: 300px;
      display: flex;
      padding: 6px;
      border-radius: 25px;
      background-color: ${colors.white8};
      .navbar-link {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        font-size: 14px !important;
        &.active {
          color: ${colors.white};
          border-radius: 25px;
          background-color: #ed2626;
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    border-radius: 0;
    height: unset;
    padding: 40px 0;
    .footer-row {
      flex-direction: column-reverse;
      &.links {
        margin-bottom: 0px;
      }
      .group-link {
        margin-bottom: 40px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        .navbar-link {
          margin-right: 0;
        }
      }
      .btns-switch {
        margin-bottom: 40px;
        width: 100%;
        .navbar-link {
          flex: 1;
        }
      }
      .group-social {
        margin-bottom: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
`;
