import styled from "styled-components";
import { colors, mixins } from "theme";

export const CareerContentContainer = styled.div`
  width: 100%;
  padding: 140px 0;
  .detail-wrapper {
    max-width: 1140px;
    margin: auto;
    .detail-navigation {
      color: ${colors.gray};
      font-size: 20px !important;
      margin-bottom: 50px;
      a,
      i {
        text-decoration: none;
        color: #989898;
      }
      i {
        margin: 0 5px;
      }
    }
    .detail-navigation-mobile {
      display: none;
    }
    .job-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: solid 1px ${colors.gray};
      border-bottom: solid 1px ${colors.gray};
      padding: 40px 0;
      margin-bottom: 40px;
      .left-part {
        h3 {
          margin: 0;
          font-size: 60px !important;
          font-weight: 900;
          color: ${colors.red};
        }
      }
      .right-part {
        h6 {
          display: none;
        }
        .apply-button {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          width: 200px;
          height: 50px;
          border-radius: 100px;
          background-color: ${colors.red};

          font-size: 18px !important;
          font-weight: 600;
          color: #ffffff;

          border: none;
        }
      }
    }
    .job-body {
      display: flex;
      align-items: baseline;

      .left-part {
        flex: 1 1 60%;
        h6 {
          margin: 0;
          margin-bottom: 30px;
          font-size: 24px !important;
          font-weight: 600;
          color: rgba(21, 21, 21, 0.5);
        }
        p {
          margin: 0;
          font-size: 24px !important;
          color: ${colors.gray};
          margin-bottom: 50px;
        }
        h4 {
          margin: 0;
          color: ${colors.red};
          font-size: 40px !important;
          font-weight: 900;
          margin-bottom: 30px;
        }
        ul {
          padding-left: 20px;
          list-style: disc;
          margin-bottom: 50px;
          li {
            position: relative;
            margin-bottom: 20px;
            font-size: 24px !important;
            color: rgba(21, 21, 21, 1);
            &::marker {
              color: ${colors.red};
            }
          }
          li::before {
            content: "";
            position: absolute;
            color: ${colors.red};
            font-size: 50px !important;
            font-weight: bold;
            display: inline-block;
            top: -20px;
            width: 1em;
            margin-left: -1em;
          }
        }
      }
      .right-part {
        flex: 1 1 40%;
        padding-left: 100px;
        .description-block {
          margin-bottom: 25px;
          b {
            font-size: 24px !important;
          }
          p {
            font-size: 24px !important;
            color: ${colors.gray};
            margin: 0;
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    padding: 50px 0;
    .detail-wrapper {
      .detail-navigation {
        display: none;
      }
      .detail-navigation-mobile {
        display: initial;
        a {
          font-size: 16px !important;
          color: #989898;
        }
      }
      .job-header {
        border-top: none;
        border-bottom: none;
        padding: 30px 0;
        margin-bottom: 0px;
        flex-direction: column;
        .left-part {
          h3 {
            font-size: 32px !important;
          }
        }
        .right-part {
          // display: none;
          width: 100%;
          padding-top: 20px;
          h6 {
            display: initial;
            font-size: 16px !important;
            font-weight: 600;
            color: rgba(21, 21, 21, 0.5);
          }
          .apply-button {
            width: 100%;
            height: 40px;
            font-size: 16px !important;
            margin-top: 40px;
          }
        }
      }

      .job-body {
        flex-direction: column-reverse;
        padding-top: 30px;
        .right-part {
          padding-left: 0;
          padding-bottom: 40px;
          .description-block {
            b,
            p {
              font-size: 16px !important;
            }
          }
        }

        .left-part {
          h6 {
            display: none;
            font-size: 16px !important;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px !important;
            margin-bottom: 60px;
          }
          ul {
            margin-bottom: 60px;
            padding-left: 20px;
            list-style: disc;
            li {
              font-size: 16px !important;
              margin-bottom: 15px;
            }
            li:before {
              content: "";
              position: absolute;
              color: #ed2626;
              font-size: 30px !important;
              font-weight: bold;
              display: inline-block;
              top: -12px;
              width: 1em;
              margin-left: -20px;
            }
          }
          h4 {
            font-size: 18px !important;
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
