import React from "react";
import { MyPhoneInputContainer, MyPhoneFormInputContainer } from "./style";

function MyPhoneInput({ type = "field", onChange = () => {} }) {
  return type === "field" ? (
    <MyPhoneInputContainer
      className="writable-input-phone"
      type={type}
      placeholder="Enter your mobile number"
      defaultCountry="US"
      onChange={onChange}
    />
  ) : (
    <MyPhoneFormInputContainer
      className="writable-input-phone"
      type={type}
      placeholder="Enter your mobile number"
      defaultCountry="US"
      onChange={onChange}
    />
  );
}

export default MyPhoneInput;
