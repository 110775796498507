/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CareerDetailContainer } from "./style";
import ApplyJob from "./ApplyJob";
import CareersHeader from "containers/Careers/CareersHeader";
import CareerContent from "./CareerContent";
import { useHistory, useParams } from "react-router";

function CareerDetail() {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [detail, setDetail] = useState({});

  const getOpenPositions = () => {
    setIsLoading(true);
    fetch(`${window.location.origin}/open-positions.json`).then((response) => {
      response.json().then((posts) => {
        setDetail(posts.find((_post) => _post.id == id));
        setTimeout(() => setIsLoading(false), 1000);
      });
    });
  };

  useEffect(() => {
    getOpenPositions();
  }, [history.location.pathname]);

  return (
    <CareerDetailContainer>
      <CareersHeader />
      <CareerContent detail={detail} isLoading={isLoading} />
      <ApplyJob detail={detail} />
    </CareerDetailContainer>
  );
}

export default CareerDetail;
