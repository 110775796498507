import styled from "styled-components";
import { colors, mixins } from "theme";

export const GetNotifiedContainer = styled.div`
  position: relative;
  padding: 50px 0;
  background: ${colors.white};
  display: flex;
  align-items: flex-start;
  padding: 200px 0;
  .container {
    max-width: 1400px;
    .get-notified-parts {
      display: flex;
      align-items: center;
      .get-notified-part {
        width: 50%;
        .form-wrapper {
          display: flex;
          justify-content: center;
        }
        .img-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          margin-left: 100px;
          .img-notified {
            max-width: ${(props) =>
              props.isIn === "restaurants" ? 834 : 596}px;
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    padding: 100px 0;
    flex-direction: column;
    .container {
      .get-notified-parts {
        flex-direction: column;
        .get-notified-part {
          width: 100%;
          .form-wrapper {
            display: unset;
            padding-top: 0px;
            .get-notified-form {
              width: 100%;
            }
          }
          .img-wrapper {
            margin-left: 0;
            margin-top: 50px;
            .img-notified {
              width: 80%;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
