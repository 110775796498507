import styled from "styled-components";
import { colors, mixins } from "theme";

export const ApplyJobContainer = styled.div`
  width: 100%;
  padding: 200px 0;
  background-image: linear-gradient(
    117deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1) 100%
  );
  .container {
    display: flex;
    align-items: flex-start;
    .apply-left {
      width: 40%;
      h1 {
        font-size: 90px !important;
        line-height: 1.11;
        letter-spacing: -2.16px;
      }
    }
    .apply-right {
      width: 60%;
      .ant-col {
        label {
          opacity: 0.5;
          font-family: Montserrat;
          font-size: 14px !important;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.14;
          letter-spacing: -0.34px;
          color: ${colors.gray};
        }
      }
      .btns-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn {
          width: 300px;
          height: 50px;
        }
      }
      .file-upload {
        width: 100%;
        border-radius: 4px;
        .ant-upload {
          &.ant-upload-drag {
            background: #f3f3f3;
            border: 1px dashed #d4d4d4;
            border-radius: 8px;
            &:hover {
              border: 1px dashed ${colors.green};
            }
          }
          .ant-upload-btn {
            padding: 0;
            .uploader-container {
              width: 100%;
              height: 152px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              .uploader-icon {
                width: 100%;
                margin-bottom: 10px;
                i {
                  font-size: 20px;
                }
              }
              p {
                font-size: 14px;
              }
              span {
                font-size: 12px;
                /* opacity: 0.4; */
                &.ant-spin-dot {
                  opacity: 1;
                  font-size: 36px;
                }
              }
            }
          }
        }
        .ant-upload-list {
          .ant-upload-list-text-container {
            .ant-upload-list-item {
              height: 50px;
              .ant-upload-list-item-info {
                padding: 0 20px;
                .ant-upload-list-item-name {
                  opacity: 0.7;
                  font-family: Montserrat;
                  font-size: 14px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 2.14;
                  letter-spacing: -0.34px;
                  color: #191919;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    padding: 50px 0;
    .container {
      flex-direction: column;
      .apply-left {
        width: 100%;
        h1 {
          font-size: 45px !important;
          line-height: 1.22;
          letter-spacing: -1.08px;
          margin-bottom: 50px;
        }
      }
      .apply-right {
        width: 100%;
        .btns-wrapper {
          flex-direction: column;
          margin-top: 30px;
          .ant-row {
            width: 100%;
            .btn {
              width: 100%;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
