import { SliderImg1, SliderImg2, SliderImg3 } from "assets/images/restaurants";
import React, { useState } from "react";
import { Carousel, Container, Image } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useInViewInit } from "service";
import { SectionSliderContainer } from "./style";

function SectionSlider() {
  const [activeIndex, setActiveIndex] = useState(0);
  const inView = useInViewInit({
    rootMargin: "-130px",
  });

  const onSlide = (key, direction) => {
    setActiveIndex(key);
  };
  return (
    <div ref={inView.ref}>
      {inView.inView ? (
        <SectionSliderContainer activeIndex={activeIndex}>
          <Container className="d-flex flex-column" fluid>
            <div className="slider-wrapper d-none d-md-flex">
              <Carousel
                slide
                interval={inView.inView ? 4000 : null}
                onSlide={onSlide}
                nextIcon={
                  <div className="icon-arrow">
                    <FaChevronRight size={25} />
                  </div>
                }
                prevIcon={
                  <div className="icon-arrow">
                    <FaChevronLeft className="icon-left" size={25} />
                  </div>
                }
              >
                <Carousel.Item>
                  <div className="slide1 slide-item">
                    <div className="slide-bg">
                      <div className="slide-left">
                        <h6 className="subtitle">
                          How Feasttt boosts your average order.
                        </h6>
                        <h1 className="text-white">
                          Customers order
                          <br /> more <span>in app.</span>
                        </h1>
                        <h6 className="description">
                          Customers ordering through the app are more likely to
                          add more items than they would ordering directly
                          through a server.
                        </h6>
                      </div>
                      <div className="slide-right"></div>
                    </div>
                    <div className="img-wrapper">
                      <Image src={SliderImg1} />
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide2 slide-item">
                    <div className="slide-bg">
                      <div className="slide-left">
                        <h6 className="subtitle">
                          How Feasttt boosts your average order.
                        </h6>
                        <h1 className="text-red-gra">
                          Customizations
                          <br /> <span>and</span> add-ons.
                        </h1>
                        <h6 className="description">
                          Offer your customers add-ons, side dishes, and other
                          preferences so they can build an order that’s
                          completely personalized to them.
                        </h6>
                      </div>
                      <div className="slide-right"></div>
                    </div>
                    <div className="img-wrapper">
                      <Image src={SliderImg2} height={700} />
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide3 slide-item">
                    <div className="slide-bg">
                      <div className="slide-left">
                        <h6 className="subtitle">
                          How Feasttt boosts your average order.
                        </h6>
                        <h1 className="text-red-gra">
                          Orders <span>and</span> refills <br /> on-demand.
                        </h1>
                        <h6 className="description">
                          Customers can continue adding to their order at any
                          time throughout the dining experience without waiting
                          for a server to come by.
                        </h6>
                      </div>
                      <div className="slide-right"></div>
                    </div>
                    <div className="img-wrapper">
                      <Image src={SliderImg3} height={700} />
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="slider-sm-wrapper d-md-none">
              <Carousel
                slide
                interval={4000}
                onSlide={onSlide}
                nextIcon={
                  <div className="icon-arrow">
                    <FaChevronRight size={25} />
                  </div>
                }
                prevIcon={
                  <div className="icon-arrow">
                    <FaChevronLeft className="icon-left" size={25} />
                  </div>
                }
              >
                <Carousel.Item>
                  <div className="slide1 slide-item">
                    <h6 className="subtitle">
                      How Feasttt boosts your average order.
                    </h6>
                    <div className="img-wrapper">
                      <Image src={SliderImg1} />
                    </div>
                    <h3 className="text-white">
                      Customers order
                      <br /> more <span>in app.</span>
                    </h3>
                    <p className="description">
                      Customers ordering through the app are more likely to add
                      more items than they would ordering directly through a
                      server.
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide2 slide-item">
                    <h6 className="subtitle">
                      How Feasttt boosts your average order.
                    </h6>
                    <div className="img-wrapper">
                      <Image src={SliderImg2} />
                    </div>
                    <h3 className="text-red-gra">
                      Customizations
                      <br /> <span>and</span> add-ons.
                    </h3>
                    <p className="description">
                      Offer your customers add-ons, side dishes, and other
                      preferences so they can build an order that’s completely
                      personalized to them.
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slide3 slide-item">
                    <h6 className="subtitle">
                      How Feasttt boosts your average order.
                    </h6>
                    <div className="img-wrapper">
                      <Image src={SliderImg3} />
                    </div>
                    <h3 className="text-red-gra">
                      Orders <span>and</span> refills <br /> on-demand.
                    </h3>
                    <p className="description">
                      Customers can continue adding to their order at any time
                      throughout the dining experience without waiting for a
                      server to come by.
                    </p>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </Container>
        </SectionSliderContainer>
      ) : (
        <div />
      )}
    </div>
  );
}

export default SectionSlider;
