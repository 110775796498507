import { Col, Row } from "antd";
import {
  IconFeature1,
  IconFeature3,
  IconFeature4,
  IconFeature5,
  IconFeature6,
  IconFeature7,
} from "assets/images/icons";
import { FeaturesMobile } from "assets/images/restaurants";
import React from "react";
import { Image } from "react-bootstrap";
import { FeaturesContainer } from "./style";

const features = [
  {
    icon: IconFeature7,
    title: "Fast & Easy Customer Check-In",
  },
  {
    icon: IconFeature5,
    title: "Incoming Orders & Requests",
  },
  {
    icon: IconFeature4,
    title: "Menus & Add-Ons",
  },
  {
    icon: IconFeature3,
    title: "Tables & Availability",
  },
  {
    icon: IconFeature6,
    title: "Staff Accounts",
  },
  {
    icon: IconFeature1,
    title: "Web and Mobile Apps",
  },
];
function Features() {
  return (
    <FeaturesContainer>
      <div className="d-flex flex-column">
        <div className="features-wrapper">
          <div className="features-card">
            <div className="card-left">
              <Image src={FeaturesMobile} className="d-md-none" width="100%" />
            </div>
            <div className="card-right">
              <div className="features-content">
                <h1 className="subtitle">
                  Everything you
                  <br />
                  need to manage
                  <br />
                  your restaurant.
                </h1>
                <Row
                  gutter={[
                    { xs: 0, lg: 0 },
                    { xs: 22, lg: 30 },
                  ]}
                >
                  {features.map((_feature, _i) => (
                    <Col key={_i} xs={24} md={24}>
                      <div className="feature-item d-flex align-items-center">
                        <Image src={_feature.icon} />
                        <h6 className="mb-0 ml-3">{_feature.title}</h6>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FeaturesContainer>
  );
}

export default Features;
