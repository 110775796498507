import React from "react";
import { DineOutContainer } from "./style";
import useMediaQuery from "use-mediaquery";
import { MobileGroup, MobileGroupMobile } from "assets/images/customer";
import { IconChef } from "assets/images/icons";
import { Image } from "react-bootstrap";
import { mixins } from "theme";

function DineOut() {
  const isMobile = useMediaQuery(`(max-width: ${mixins.md}px)`);

  return (
    <DineOutContainer>
      <div className="left-part">
        {/* <Parallax className="img-container" y={[-10, 20]} tagOuter="figure"> */}
        <Image
          src={isMobile ? MobileGroupMobile : MobileGroup}
          alt="mobile-rain"
          className="mobile-phones-img"
        />
        {/* </Parallax> */}
      </div>
      <div className="right-part">
        <h1>
          Dine out <br /> with Feasttt.
        </h1>
        <div className="small-menues">
          <div className="menu-item">
            <div className="icon-wrapper">
              <img className="chef-cap-ico" src={IconChef} alt="chef" />{" "}
            </div>
            <p>
              Find restaurants by craving, dietary preferences, budget or
              rating.
            </p>
          </div>
          <div className="menu-item">
            <div className="icon-wrapper">
              <i className="fas fa-star" />{" "}
            </div>
            <p>Browse visual menus and foodie reviews.</p>
          </div>
          <div className="menu-item">
            <div className="icon-wrapper">
              <i className="fas fa-utensils" />{" "}
            </div>
            <p>Food and drinks made to order.</p>
          </div>
          <div className="menu-item">
            <div className="icon-wrapper">
              <i className="fas fa-sync-alt"></i>{" "}
            </div>
            <p>Fast and easy refills and returns.</p>
          </div>
          <div className="menu-item">
            <div className="icon-wrapper">
              <i className="fas fa-credit-card" />{" "}
            </div>
            <p>Contactless payments and tips.</p>
          </div>
          <div className="menu-item">
            <div className="icon-wrapper">
              <i className="fas fa-users" />
            </div>
            <p>Split the bill without the headache.</p>
          </div>
        </div>
      </div>
    </DineOutContainer>
  );
}

export default DineOut;
