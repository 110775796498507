import styled from "styled-components";
import { colors, mixins } from "theme";

export const PrivacyContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  padding: 100px 0;
  .privacy-wrapper {
    padding-top: 50px;
    word-wrap: break-word;
    h1 {
      font-size: 2.125rem !important;
      margin-top: 25px;
      margin-bottom: 15px;
    }
    h2 {
      font-size: 1.65rem !important;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 1.35rem !important;
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    p {
      font-size: 1rem !important;
    }
    ul {
      font-size: 1rem !important;
      line-height: 2;
      li {
        p {
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
