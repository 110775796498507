/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Skeleton } from "antd";
import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CareerContentContainer } from "./style";

function CareerContent({ detail, isLoading = true }) {
  return (
    <CareerContentContainer>
      <Container className="d-flex flex-column">
        {isLoading ? (
          <Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </Fragment>
        ) : (
          <div className="detail-wrapper">
            <div className="detail-navigation">
              <Link to="/careers">Careers</Link>{" "}
              <i className="fas fa-angle-right" /> {detail.name}
            </div>

            <div className="detail-navigation-mobile">
              <Link to="/careers">
                <i className="fas fa-angle-left" /> Back to the Careers
              </Link>
            </div>

            <div className="job-header">
              <div className="left-part">
                <h3>{detail.name}</h3>
              </div>
              <div className="right-part">
                <h6>{detail.location}</h6>
                <a href="#applyJob" className="apply-button btn btn-red">
                  Apply
                </a>
              </div>
            </div>
            <div className="job-body">
              <div className="left-part">
                <h6 className="text-bold">{detail.location}</h6>
                <p>{detail.foreword}</p>

                <h4>What you’ll do</h4>
                <ul>
                  {detail.expectations &&
                    detail.expectations.map((_expectation, _i) => {
                      return <li key={_i}>{_expectation}</li>;
                    })}
                </ul>

                <h4>We offer</h4>
                <ul>
                  {detail.offer &&
                    detail.offer.map((_propos, _i) => {
                      return <li key={_i}>{_propos}</li>;
                    })}
                </ul>
              </div>
              <div className="right-part">
                <div className="description-block">
                  <b>Seniority Level</b>
                  <p>{detail.requestedLevel}</p>
                </div>
                <div className="description-block">
                  <b>Industry</b>
                  <p>{detail.industry}</p>
                </div>
                <div className="description-block">
                  <b>Employment Type</b>
                  <p>{detail.employmentType}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </CareerContentContainer>
  );
}

export default CareerContent;
