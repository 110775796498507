import { createGlobalStyle } from "styled-components";
import { colors, fonts, mixins } from "theme";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${fonts.main};
    margin: 0;
    height: 100%;
  }
  .App {
    height: 100vh;
  }
  .cursor {
    cursor: pointer;
  }
  h1 {
    font-size: 56px !important;
    font-family: ${fonts.main};
    font-weight: 700;
  }
  h2 {
    font-size: 46px !important;
    font-family: ${fonts.main};
    font-weight: 700;
  }
  h3 {
    font-size: 36px !important;
    font-family: ${fonts.main};
  }
  h5 {
    font-size: 24px !important;
  }
  h6 {
    font-size: 20px !important;
    font-family: ${fonts.main};
  }
  p {
    font-size: 14px !important;
    font-family: ${fonts.main};
  }
  .flex1 {
    flex: 1;
  }
  .section-wrapper {
    min-height: 200px;
  }
  .text-red-gra {
    background-image: linear-gradient(104deg, #ed2626, #ff7878);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -1.92px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .btn-red {
    background: ${colors.red};
    color: ${colors.white};
    &:active, &:hover, &:focus {
      background-color: ${colors.red} !important;
      color: ${colors.white} !important;
    }
  }
  .btn-white {
    background: ${colors.white};
    color: ${colors.red};
    &:active, &:hover, &:focus {
      background-color: ${colors.white} !important;
      color: ${colors.red} !important;
    }
  }
  .btn-gray {
    background: ${colors.grayLight};
    color: #000;
    &:active, &:hover, &:focus {
      background-color: ${colors.grayLight} !important;
      color: #000 !important;
    }
  }
  .text-white5 {
    color: ${colors.white5};
  }
  .text-dark5 {
    color: ${colors.dark5};
  }
  .text-red {
    color: ${colors.red};
  }
  .text-bold {
    font-weight: bold;
  }
  .bg-dark5 {
    background-color: ${colors.dark5};
  }
  .bg-red {
    background-color: ${colors.red};
  }
  button, .btn {
    font-family: ${fonts.main};
    font-weight: 700;
    border: none;
    border-radius: 100px;
    transition: opacity .45s cubic-bezier(0.25, 1, 0.33, 1), transform .45s cubic-bezier(0.25, 1, 0.33, 1), border-color .45s cubic-bezier(0.25, 1, 0.33, 1), color .45s cubic-bezier(0.25, 1, 0.33, 1), background-color .45s cubic-bezier(0.25, 1, 0.33, 1), box-shadow .45s cubic-bezier(0.25, 1, 0.33, 1);
    &:focus {
      outline: none;
    }
    &:hover {
      box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.16);
      transform: translateY(-3px);
    }
    &.disabled:hover {
      transform: unset;
    }
  }
  .ant-message {
    top: 0;
    .ant-message-notice {
      padding: 0;
      .ant-message-notice-content {
        width: 100%;
        height: 112px;
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-message-custom-content {
          width: 100%;
          .anticon-close-circle {
            display: none;
          }
        }
      }
    }
  }
  .modal-download {
    .ant-modal-content {
      border-radius: 44px;
      .ant-modal-body {
        padding: 0;
        .download-form {
          padding: 30px 60px 22px;
          @media (max-width: ${mixins.md}px) {
            padding: 30px 15px 22px;
          }
          .download-title {
            font-size: 36px !important;
            font-weight: bold;
          }
          .download-description {
            font-size: 20px !important;
            margin-bottom: 40px;
          }
          .user-type {
            margin-bottom: 30px;
            label {
              font-size: 16px !important;
              font-weight: bold;
            }
            .user-cards-wrapper {
              .user-card {
                flex: 1;
                min-height: 106px;
                border-radius: 6px;
                border: solid 2px rgba(0, 0, 0, 0.15);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: all 0.5s ease;
                &.selected {
                  border: solid 2px #ed2626;
                }
                p {
                  font-size: 16px;
                  font-weight: 600;
                  margin-bottom: 0;
                  margin-top: 5px;
                }
              }
            }
          }
          .ant-form {
            .recaptcha-wrapper {
              margin-top: 30px;
              div {
                margin: 0 auto;
              }
            }
            .ant-form-item {
              margin-bottom: 11px;
              .ant-form-item-label {
                label {
                  width: 100%;
                  font-size: 16px !important;
                  font-weight: bold;
                  &::before {
                    display: none;
                  }
                  span {
                    margin: 0;
                    &.optional {
                      opacity: 0.6;
                    }
                  }
                }
              }
              .btn-submit {
                width: 100%;
                height: 50px;
                margin-top: 28px;
              }
              .btn-cancel {
                width: 100%;
                height: 50px;
              }
            }
            .icon-close-modal {
              width: 50px;
              height: 50px;
              box-shadow: 0 2px 33px 0 rgb(0 0 0 / 30%);
              border-radius: 50%;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .modal-thankyou {
    .ant-modal {
      width: 600px !important;
      .ant-modal-content {
        border-radius: 50px;
        .ant-modal-body {
          padding: 0;
          .download-form {
            padding: 30px 60px 22px;
            @media (max-width: ${mixins.md}px) {
              padding: 30px 15px 22px;
            }
            .download-title {
              font-size: 36px !important;
              font-weight: bold;
            }
            .download-description {
              font-size: 24px !important;
              margin-bottom: 20px;
            }
            .down-again {
              font-size: 16px !important;
              text-align: center;
            }
            .btn-submit {
              width: 100%;
              height: 50px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
`;

export default GlobalStyle;
