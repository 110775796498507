import React from "react";
import { MyTextareaContainer } from "./style";

function MyTextarea({ className = "", placeholder = "", onChange = () => {} }) {
  return (
    <MyTextareaContainer
      className={className}
      placeholder={placeholder}
      rows={4}
      onChange={onChange}
    />
  );
}

export default MyTextarea;
