import React from "react";
import { ContactInfoContainer } from "./style";

function ContactInfo() {
  return (
    <ContactInfoContainer>
      <div className="contact-info-wrapper">
        <div className="contact-info-left"></div>
        <div className="contact-info-right">
          <div className="contact-item">
            <p className="upper">
              <i className="fas fa-phone" />
              <span>PHONE</span>
            </p>
            <p className="bottom">+9 927 138 37 15</p>
          </div>

          <div className="contact-item">
            <p className="upper">
              <i className="far fa-envelope" />
              <span>E-MAIL</span>
            </p>
            <p className="bottom">contactus@feasttt.com</p>
          </div>

          <div className="contact-item">
            <p className="upper">
              <i className="fas fa-map-marker-alt" />
              <span>HEADQUARTERS</span>
            </p>
            <p className="bottom">New York City, NY</p>
          </div>
        </div>
      </div>
    </ContactInfoContainer>
  );
}

export default ContactInfo;
