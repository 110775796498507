import { GetCustomers } from "assets/images/customer";
import { GetRestaurants } from "assets/images/restaurants";
import GetNotifiedForm from "components/sections/GetNotifiedForm";
import React from "react";
import { Container, Image } from "react-bootstrap";
import { GetNotifiedContainer } from "./style";

function GetNotified({ isIn = "restaurants" }) {
  return (
    <GetNotifiedContainer isIn={isIn}>
      <Container>
        <div className="get-notified-parts">
          <div className="get-notified-part">
            <div className="form-wrapper">
              <GetNotifiedForm />
            </div>
          </div>
          <div className="get-notified-part">
            <div className="img-wrapper">
              <Image
                src={isIn === "restaurants" ? GetRestaurants : GetCustomers}
                className="img-notified"
              />
            </div>
          </div>
        </div>
      </Container>
    </GetNotifiedContainer>
  );
}

export default GetNotified;
