import { Input } from "antd";
import styled from "styled-components";
import { mixins } from "theme";

export const MyInputContainer = styled(Input)`
  width: 100%;
  height: 50px;
  padding: 10px 10px 10px 18px;
  border-radius: 8px;
  background-color: #fafafa;
  @media (max-width: ${mixins.md}px) {
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
