/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CareersContainer } from "./style";
import CareersHeader from "./CareersHeader";
import OpenPositions from "./OpenPositions";

function Careers() {
  return (
    <CareersContainer>
      <CareersHeader />
      <OpenPositions />
    </CareersContainer>
  );
}

export default Careers;
