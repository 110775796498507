export const fonts = {
  roboto: 'Roboto',
  pt: "PT Sans",
  nunito: 'Nunito',
  main: 'Montserrat',
}

export const colors = {
  white: '#fff',
  white5: 'rgba(255, 255, 255, 0.5)',
  white8: 'rgba(255, 255, 255, 0.08)',
  green: 'green',
  grayDark: '#151617',
  grayLight: '#D1D1D1',
  gray: '#191919',
  red: '#ed2626',
  dark5: 'rgba(0, 0, 0, 0.5)',
  pink6: `rgba(237, 38, 38, 0.06)`
}

export const mixins = {
  sm: 575,
  md: 767,
  mobileNavbarH: 146,
}