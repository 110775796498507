import { Col, Row, Skeleton } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { OpenPositionsContainer } from "./style";

function OpenPositions() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [openPositions, setOpenPositions] = useState([]);

  const getOpenPositions = () => {
    setIsLoading(true);
    fetch(`${window.location.origin}/open-positions.json`).then((response) => {
      response.json().then((posts) => {
        setOpenPositions(posts);
        setTimeout(() => setIsLoading(false), 1000);
      });
    });
  };

  useEffect(() => {
    getOpenPositions();
  }, [history.location.pathname]);

  return (
    <OpenPositionsContainer>
      <Container className="d-flex flex-column">
        {isLoading ? (
          <Fragment>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </Fragment>
        ) : (
          <Row justify="center" gutter={[30, 40]}>
            <Col lg={12} md={24} xs={24}>
              <div className="open-left">
                <h1>
                  Open <br />
                  positions.
                </h1>
              </div>
            </Col>
            <Col lg={12} md={24} xs={24}>
              <div className="open-right">
                {openPositions.length > 0 &&
                  openPositions.map((_position, _i) => {
                    return (
                      <div key={_i} className="position-item">
                        <div className="position-left">
                          <h6 className="text-bold">{_position.name}</h6>
                          <h6 className="mb-0">{_position.location}</h6>
                        </div>
                        <div className="position-right">
                          <Link key={_i} to={`/careers/${_position.id}`}>
                            <h6 className="mb-0">
                              View <i className="fas fa-angle-right" />
                            </h6>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </OpenPositionsContainer>
  );
}

export default OpenPositions;
