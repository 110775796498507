import { Col, Row } from "antd";
import React from "react";
import { Container, Image } from "react-bootstrap";
import { HowTo1, HowTo2, HowTo3 } from "assets/images/customer";
import { IconFeasttt } from "assets/images/icons";
import { HowToFeastttContainer } from "containers/Restaurants/HowToFeasttt/style";

function HowToFeasttt() {
  // const inView = useInViewInit({
  //   rootMargin: "-130px",
  //   threshold: 1,
  //   triggerOnce: true,
  // });

  return (
    <HowToFeastttContainer>
      <Container className="d-flex flex-column align-items-center" fluid>
        <Image src={IconFeasttt} className="img-howto mx-auto mb-2" />
        <h1 className="how-to-feasttt">How to Feasttt.</h1>
        <div className="cards-wrapper">
          <Row justify="center" gutter={[{ sm: 0, md: 100 }, 40]}>
            <Col md={8} xs={24}>
              <div className="how-card">
                <h5>
                  Arrive and check in at <br />
                  the restaurant.
                </h5>
                <Image src={HowTo1} />
                <h6 className="how-card-description">
                  Download the Feasttt app to find your new favorite dine-in
                  restaurant then check in when you arrive to{" "}
                  <b>unlock your personalized dining experience.</b>
                </h6>
              </div>
            </Col>
            <Col md={8} xs={24}>
              <div className="how-card">
                <h5>
                  Order food, request refills
                  <br />
                  and more with a tap.
                </h5>
                <Image src={HowTo2} />
                <h6 className="how-card-description">
                  Browse menus and reviews from other foodies and customize your
                  order to your heart’s content.{" "}
                  <b>Hungry? Thirsty? Everything is just a tap away.</b>
                </h6>
              </div>
            </Col>
            <Col md={8} xs={24}>
              <div className="how-card">
                <h5>
                  Pay, split the bill
                  <br />
                  and rate your feast.
                </h5>
                <Image src={HowTo3} />
                <h6 className="how-card-description">
                  After a <b>wonderful feast</b> you can pay and tip right in
                  the app, invite friends to split the bill and rate your meal
                  and server to help future guests.
                </h6>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </HowToFeastttContainer>
  );
}

export default HowToFeasttt;
