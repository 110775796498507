import React from "react";
import { message } from "antd";
import { Container } from "react-bootstrap";
import { FaCheck, FaInfoCircle, FaTimes } from "react-icons/fa";
import { colors } from "theme";
import {
  IconBlogActive,
  IconBlogInactive,
  IconCareersActive,
  IconCareersInactive,
  IconContactActive,
  IconContactInactive,
  IconHomeActive,
  IconHomeInactive,
} from "assets/images/icons";
import { useInView } from "react-intersection-observer";
import * as Sentry from "@sentry/react";

export const showNotification = (
  type = "error",
  msg,
  className = "",
  onClose = () => {}
) => {
  message.open({
    top: 0,
    duration: 10,
    maxCount: 1,
    content: (
      <Container className="d-flex justify-content-between align-items-center">
        {type === "error" ? (
          <FaInfoCircle size={18} color={colors.red} />
        ) : (
          <FaCheck size={18} color={colors.green} />
        )}
        <h6 className="mb-0 ml-2 text-gray text-bold">{msg}</h6>
        <FaTimes
          className="ml-auto"
          size={19}
          color={colors.gray}
          onClick={onClose}
        />
      </Container>
    ),
    className: className,
    // content:
    onClose: onClose,
  });
};

export const recaptchasitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export function useInViewInit(otherProps) {
  let props = {
    ...otherProps,
    threshold: 1,
    triggerOnce: true,
  };
  let inView = true;
  let ref = React.useRef();

  try {
    const useInViewInit = useInView(props);
    inView = useInViewInit.inView;
    ref = useInViewInit.ref;
  } catch (error) {
    Sentry.addBreadcrumb({
      category: "app",
      message: "Intersection observer not available",
      level: "info",
    });
  }

  return { inView, ref };
}

export const links_home = [
  {
    title: "For Foodies",
    link: "/home/customers",
  },
  {
    title: "For Restaurants",
    link: "/home/restaurants",
  },
];
export const links_pages = [
  // {
  //   title: "Blog",
  //   link: "/blog",
  // },
  {
    title: "Careers",
    link: "/careers",
  },
  {
    title: "Contact",
    link: "/contact",
  },
  {
    title: "Privacy Policy",
    link: "/privacy",
  },
];

export const mobile_links = [
  {
    title: "Home",
    link: "/home",
    iconActive: IconHomeActive,
    iconInactive: IconHomeInactive,
  },
  // {
  //   title: "Blog",
  //   link: "/blog",
  //   iconActive: IconBlogActive,
  //   iconInactive: IconBlogInactive,
  // },
  {
    title: "Careers",
    link: "/careers",
    iconActive: IconCareersActive,
    iconInactive: IconCareersInactive,
  },
  {
    title: "Contact",
    link: "/contact",
    iconActive: IconContactActive,
    iconInactive: IconContactInactive,
  },
];
