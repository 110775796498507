/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import { Col, Form, Row, Spin, Upload } from "antd";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Container } from "react-bootstrap";
import { ApplyJobContainer } from "./style";
import MyInput from "components/Elements/MyInput";
import MyPhoneInput from "components/Elements/MyPhoneInput";
import MyTextarea from "components/Elements/MyTextarea";
import { FaUpload } from "react-icons/fa";
import { recaptchasitekey, showNotification } from "service";

const { Dragger } = Upload;

function ApplyJob({ detail = {} }) {
  const recaptchaRef = useRef();
  const [form] = Form.useForm();
  const [valReCaptcha, setValReCaptcha] = useState("");
  const [uploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null)

  const onVerifyCaptcha = (value) => {
    setValReCaptcha(value);
  };

  const onFinishFailed = () => {
    showNotification(
      "error",
      "Please complete the entire application before submitting."
    );
  };
  const onFinish = async (values) => {
    if (!valReCaptcha) {
      showNotification("error", "Please, verify captcha.");
      return;
    }
    const formData = new FormData();
    formData.append("name", values.full_name);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("letter", values.letter);
    formData.append("linkedin", values.linkedin);
    formData.append("github", values.github);
    formData.append("portfolioLink", values.portfolioLink);
    formData.append("uploadedFile", uploadedFile);
    formData.append("captchaResponse", valReCaptcha);
    formData.append(
      "positionName",
      detail.name.replaceAll(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
    );
    const response = await fetch("https://code-room.com/careers2.php", {
      method: "POST",
      body: formData,
    });
    const result = await response.json();
    if (result && result.status === "ok") {
      showNotification("success", "Thank you for submitting your application.");
    } else {
      showNotification("error", "Error occurred. Please try again later.");
    }
    recaptchaRef.current.reset();
    form.resetFields();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file) => {
    if (file.type !== "image/png") {
      showNotification("error", `${file.name} is not a png file`);
      return false;
    } else {
      setUploadedFile(file);
      // const reader = new FileReader();
      // // reader.readAsDataURL(file);
      // reader.readAsText(file);
      // reader.onload = async (e) => {};
      // return false;
    }
    return false;
  };
  return (
    <ApplyJobContainer id="applyJob">
      <Container>
        <div className="apply-left">
          <h1 className="text-red-gra">
            Apply <br />
            for job.
          </h1>
        </div>
        <div className="apply-right">
          <Form
            className="header-right"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row
              gutter={[
                { lg: 48, xs: 0 },
                { lg: 24, xs: 0 },
              ]}
            >
              <Col xs={24} md={12}>
                <Form.Item
                  name="full_name"
                  label="Full Name"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                  ]}
                  hasFeedback
                >
                  <MyInput placeholder="Enter your full name" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                    {
                      type: "email",
                      message: "Invalid Email!",
                    },
                  ]}
                  hasFeedback
                >
                  <MyInput placeholder="Enter your e-mail" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                  ]}
                  hasFeedback
                >
                  <MyPhoneInput type="form" placeholder="Enter your phone number" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="linkedin"
                  label="LinkedIn Profile (if any)"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                  ]}
                  hasFeedback
                >
                  <MyInput placeholder="Enter your Linkedin URL" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="github"
                  label="Github Profile (if any)"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                  ]}
                  hasFeedback
                >
                  <MyInput placeholder="Enter your Github URL" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="portfolioLink"
                  label="Portfolio Link (if any)"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                  ]}
                  hasFeedback
                >
                  <MyInput placeholder="Enter your Portfolio URL" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  name="letter"
                  label="Cover Letter"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                  ]}
                >
                  <MyTextarea placeholder="Write your cover letter here…" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <div className="file-upload">
                  <Form.Item
                    name="files"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      {
                        required: true,
                        message: "Required Field!",
                      },
                    ]}
                  >
                    <Dragger
                      name="files"
                      multiple={false}
                      listType="text"
                      // previewFile={false}
                      beforeUpload={beforeUpload}
                      // fileList={false}
                    >
                      <div className="uploader-container">
                        {uploading ? (
                          <Spin size="large" />
                        ) : (
                          <>
                            <div className="uploader-icon d-flex justify-content-center align-items-center">
                              <FaUpload />
                              <span className="text-red ml-2">
                                Upload Files
                              </span>
                            </div>
                            <p className="text-blue mb-0">
                              You can upload doc, docx or pdf
                            </p>
                          </>
                        )}
                      </div>
                    </Dragger>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} md={24}>
                <div className="btns-wrapper">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={recaptchasitekey}
                    onChange={onVerifyCaptcha}
                    onExpired={() => setValReCaptcha("")}
                  />
                  <Form.Item>
                    <Button type="submit" className="btn-red">
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </ApplyJobContainer>
  );
}

export default ApplyJob;
