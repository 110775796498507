/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Row } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import MyInput from "components/Elements/MyInput";
import MyPhoneInput from "components/Elements/MyPhoneInput";
import MyTextarea from "components/Elements/MyTextarea";
import React, { useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { ContactHeaderContainer } from "./style";
import { recaptchasitekey, showNotification } from "service";

function ContactHeader() {
  const recaptchaRef = useRef();
  const [form] = Form.useForm();
  const [valReCaptcha, setValReCaptcha] = useState("");

  const formItemLayout = null;
  const onVerifyCaptcha = (value) => {
    setValReCaptcha(value);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    showNotification('error', errorFields[0].errors[0])
  };

  const onFinish = async (values) => {
    if (!valReCaptcha) {
      showNotification("error", "Please, verify captcha.");
      return;
    }
    const formData = new FormData();
    formData.append("name", values.full_name);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("thoughts", values.thoughts);
    formData.append("captchaResponse", valReCaptcha);
    const response = await fetch("https://code-room.com/contact2.php", {
      method: "POST",
      body: formData,
    });
    const result = await response.json();
    if (result && result.status === 'ok') {
      showNotification('success', 'We’ve got your thoughts. We’ll contact you soon! ')
    } else {
      showNotification('error', 'Error occurred. Please try again later.')
    }
    recaptchaRef.current.reset();
    form.resetFields();
  };

  return (
    <ContactHeaderContainer>
      <Container>
        <div className="header-content">
          <Row>
            <Col xs={24} md={10}>
              <div className="header-left">
                <h1 className="text-red">
                  Get in
                  <br className="d-none d-md-block" /> touch.
                </h1>
                <h6>Typically replies within 24 hours.</h6>
              </div>
            </Col>
            <Col xs={24} md={14}>
              <Form
                {...formItemLayout}
                className="header-right"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="full_name"
                  label="Full Name"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                  ]}
                  hasFeedback
                >
                  <MyInput placeholder="Share your full name" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                  ]}
                  hasFeedback
                >
                  <MyPhoneInput type="form" placeholder="Enter your phone number" />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                    {
                      type: "email",
                      message: "Invalid Email!",
                    },
                  ]}
                  hasFeedback
                >
                  <MyInput placeholder="Enter your e-mail" />
                </Form.Item>
                <Form.Item
                  name="thoughts"
                  label="Your thoughts"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                  ]}
                >
                  <MyTextarea placeholder="Share your thoughts here…" />
                </Form.Item>
                <div className="btns-wrapper">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={recaptchasitekey}
                    onChange={onVerifyCaptcha}
                    onExpired={() => setValReCaptcha("")}
                  />
                  <Form.Item>
                    <Button type="submit" className="btn-red">
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </ContactHeaderContainer>
  );
}

export default ContactHeader;
