/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import React from "react";
import { Button, Container, Image } from "react-bootstrap";
import useMediaQuery from "use-mediaquery";
import { CustomerHeaderContainer } from "./style";
import { HeaderPhone, HeaderPhoneMobile } from "assets/images/customer";
import { mixins } from "theme";

function CustomerHeader({
  onClickComing = () => {}
}) {

  const isMobile = useMediaQuery(`(max-width: ${mixins.md}px)`);

  return (
    <CustomerHeaderContainer className='animate__animated animate__fadeIn'>
      <Container className='flex1'>
        <Row className='align-items-stretch h-100'>
          <Col md={12} xs={24}>
            <div className="header-left">
              <h1 className="header-description">
                A dining <br className='d-md-none' /> experience
                <br />
                <span>as</span> unique<br className='d-md-none' /> as you.
              </h1>
              <Button className="btn-get-the-app btn-red" onClick={onClickComing}>Coming Soon</Button>
            </div>
          </Col>
          <Col md={12} xs={24} className='h-100'>
            <div className="header-right text-right h-100">
              <Image src={isMobile ? HeaderPhoneMobile : HeaderPhone} className='header-image' />
            </div>
          </Col>
        </Row>
        {/* <Button className="btn-get-the-app-sm btn-red d-md-none">Coming Soon</Button> */}
      </Container>
    </CustomerHeaderContainer>
  );
}

export default CustomerHeader;
