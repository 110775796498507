import styled from "styled-components";
import { colors, mixins } from "theme";

export const CareersContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
    117deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1) 100%
  );
  @media (max-width: ${mixins.md}px) {
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
