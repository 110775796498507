import { FeaturesLeft } from "assets/images/restaurants";
import styled from "styled-components";
import { colors, mixins } from "theme";

export const FeaturesContainer = styled.div`
  width: 100%;
  .features-wrapper {
    display: flex;
    justify-content: center;
    .features-card {
      width: 100%;
      background-color: ${colors.pink6};
      display: flex;
      .card-left {
        flex: 1;
        background: url(${FeaturesLeft});
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: left;
      }
      .card-right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 150px 0;
        .features-content {
          width: 80%;
          h5.title {
            background-image: linear-gradient(102deg, #ed2626, #ff7878);
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          h1.subtitle {
            background-image: linear-gradient(99deg, #ed2626, #ff7878);
            font-size: 80px !important;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -1.92px;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 60px;
          }
          .feature-item {
            img {
              width: 48px;
            }
            h6 {
              line-height: 1.6;
              color: ${colors.gray};
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    padding: 0;
    .features-wrapper {
      .features-card {
        flex-direction: column-reverse;
        height: unset;
        .card-left {
          background: transparent;
        }
        .card-right {
          padding: 75px 15px;
          .features-content {
            margin-right: 0;
            width: 100%;
            h5.title {
              font-size: 20px !important;
              margin-bottom: 20px;
              text-align: center;
            }
            h1.subtitle {
              font-size: 32px !important;
              margin-bottom: 45px;
              text-align: left;
              letter-spacing: normal;
            }
            .feature-item {
              h6 {
                font-size: 14px !important;
                font-weight: bold;
                color: ${colors.gray};
              }
              img {
                width: 36px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
