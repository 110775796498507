import { IconHamburger, LogoRed } from "assets/images/icons";
import React, { useState } from "react";
import { Drawer } from "antd";
import { Image, Container } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { Link, useHistory, useLocation } from "react-router-dom";
import { links_home, links_pages, mobile_links } from "service";
import { mixins } from "theme";
import useMediaQuery from "use-mediaquery";
import { TopbarContainer } from "./style";
import { LogoMobile } from "assets/images";

function Topbar({ scrolled }) {
  const history = useHistory();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };

  const isShowBtnsOnMobile = location.pathname.includes("home");
  const isMobile = useMediaQuery(`(max-width: ${mixins.md}px)`);
  const isWhite = !location.pathname.includes("home");

  const onClickNavLink = (link) => {
    if (link === "/home") {
      history.push("/home/customers");
    } else {
      history.push(link);
    }
    setVisible(false);
  };
  return (
    <TopbarContainer
      className={`${expanded ? "expanded-nav" : "collapsed-nav"}`}
      collapseOnSelect
      expand="md"
      fixed="top"
      bg={expanded || scrolled || isWhite ? "white" : "transparent"}
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
    >
      <Container>
        {isMobile ? (
          <div
            id="navbar-wrapper-sm"
            className="navbar-wrapper-sm d-flex justify-content-between align-items-center"
          >
            <Image
              src={IconHamburger}
              className="icon-hamburger"
              width={30}
              onClick={showDrawer}
            />
            <Link
              to="/"
              className="logo-img flex1 d-flex justify-content-center align-items-center"
            >
              <Image src={LogoRed} width={136} />
            </Link>
            <Drawer
              title={false}
              placement="right"
              closable={false}
              onClose={() => setVisible(false)}
              visible={visible}
              width="100%"
              getContainer={() => document.getElementById("navbar-wrapper-sm")}
            >
              <Image src={LogoMobile} width={150} />
              <div className="navbar-sm-content">
                {mobile_links
                  .filter((_link, _i) => _link.link !== "/privacy")
                  .map((_link, _i) => (
                    <div
                      className="sm-link"
                      onClick={() => onClickNavLink(_link.link)}
                    >
                      <div className="link-icon d-flex justify-content-center align-items-center">
                        {/* <FaCircle
                          size={30}
                          color={
                            location.pathname.includes(_link.link)
                              ? colors.red
                              : colors.dark5
                          }
                        /> */}
                        <Image
                          src={
                            location.pathname.includes(_link.link)
                              ? _link.iconActive
                              : _link.iconInactive
                          }
                          width={30}
                        />
                      </div>
                      <div className="link-title">
                        <h5
                          key={_i}
                          to={_link.link}
                          className={`navbar-link ${
                            location.pathname.includes(_link.link)
                              ? "text-red"
                              : "text-dark5"
                          }`}
                          onClick={() => setExpanded(false)}
                        >
                          {_link.title}
                        </h5>
                      </div>
                    </div>
                  ))}
              </div>
              <div
                className={`btns-switch-sm ${
                  !isShowBtnsOnMobile && isMobile && "d-none"
                }`}
              >
                {links_home.map((_link, _i) => (
                  <h5
                    key={_i}
                    to={_link.link}
                    className={`${
                      _link.link === location.pathname && "active"
                    } navbar-link ml-auto`}
                    onClick={() => onClickNavLink(_link.link)}
                  >
                    {_link.title}
                  </h5>
                ))}
              </div>
              <div
                className="d-md-none d-flex close-icon mx-auto mt-auto"
                onClick={() => setVisible(false)}
              >
                <FaTimes size={22} />
              </div>
            </Drawer>
          </div>
        ) : (
          <div className="navbar-wrapper">
            <Link to="/" className="logo-img">
              <Image src={LogoRed} />
            </Link>
            {links_pages
              .filter((_link, _i) => _link.link !== "/privacy")
              .map((_link, _i) => (
                <Link
                  key={_i}
                  to={_link.link}
                  className={`navbar-link text-dark5`}
                  onClick={() => setExpanded(false)}
                >
                  {_link.title}
                </Link>
              ))}
            <div
              className={`btns-switch ml-auto ${
                !isShowBtnsOnMobile && isMobile && "d-none"
              }`}
            >
              {links_home.map((_link, _i) => (
                <Link
                  key={_i}
                  to={_link.link}
                  className={`${
                    _link.link === location.pathname && "active"
                  } navbar-link ml-auto`}
                >
                  {_link.title}
                </Link>
              ))}
            </div>
          </div>
        )}
      </Container>
    </TopbarContainer>
  );
}

export default Topbar;
