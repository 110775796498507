import styled from "styled-components";
import { colors, mixins } from "theme";

export const DineOutContainer = styled.div`
  background-color: ${colors.pink6};
  max-height: 1200px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  .left-part {
    flex: 1;
    .mobile-phones-img {
      width: 100%;
      position: relative;
      left: -100px;
    }
  }
  .right-part {
    flex: 1;
    // padding-right: 100px;
    h1 {
      font-size: 90px !important;
      font-weight: bold;
      background-image: linear-gradient(111deg, #ed2626, #ff7878 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0;
      margin-bottom: 80px;
      letter-spacing: -1.92px;
    }
    .small-menues {
      .menu-item {
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        .icon-wrapper {
          width: 50px;
          height: 50px;
          min-width: 50px;
          border-radius: 50%;
          background-image: linear-gradient(291deg, #ff6767, #ed2626);
          margin-right: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          .chef-cap-ico {
            width: 18px;
          }
          i {
            font-size: 18px !important;
            color: ${colors.white};
          }
        }
        p {
          font-size: 24px !important;
          font-weight: bold;
          color: ${colors.gray};
          margin: 0;
          max-width: 500px;
          letter-spacing: -0.58px;
          text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.55);
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    max-height: unset;
    flex-direction: column-reverse;
    .left-part {
      padding: 0 15px;
      .mobile-phones-img {
        left: 0;
      }
    }
    .right-part {
      padding: 80px 30px;
      width: 100%;
      h1 {
        font-size: 45px !important;
        margin-bottom: 40px;
        letter-spacing: normal;
      }
      .small-menues {
        .menu-item {
          display: flex;
          padding-bottom: 21px;
          .icon-wrapper {
            .chef-cap-ico {
              width: 16px;
            }
            i {
              font-size: 16px !important;
            }
          }
          p {
            font-size: 14px !important;
            letter-spacing: -0.34px;
            line-height: 1.86;
            text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.55);
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
