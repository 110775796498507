import styled from "styled-components";
import { colors, mixins } from "theme";

export const ContactHeaderContainer = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    117deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.3) 100%
  );
  .header-content {
    padding: 200px 0;
    .header-left {
      h1 {
        font-size: 90px !important;
        line-height: 1.11;
        letter-spacing: -2.16px;
      }
    }
    .header-right {
      .btns-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn {
          width: 300px;
          height: 50px;
        }
      }
      .ant-col {
        label {
          opacity: 0.5;
          font-family: Montserrat;
          font-size: 14px !important;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.14;
          letter-spacing: -0.34px;
          color: ${colors.gray};
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    max-height: unset;
    flex-direction: column;
    background-size: 100%;
    background-position: top;
    .header-content {
      padding: 45px 0;
      .header-left {
        margin-bottom: 40px;
        h1 {
          font-size: 45px !important;
        }
        h6 {
          font-size: 16px !important;
        }
      }
      .header-right {
        .btns-wrapper {
          flex-direction: column;
          margin-top: 30px;
          .ant-row {
            width: 100%;
            .btn {
              width: 100%;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
