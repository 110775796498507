/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { CustomersContainer } from "./style";
import CustomerHeader from "./CustomerHeader";
import HowToFeasttt from "./HowToFeasttt";
import DineOut from "./DineOut";
import Download from "./Download";
import GetNotified from "components/sections/GetNotified";

function Customers() {
  const ref = useRef(null);

  const onClickComing = () => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <CustomersContainer>
      <CustomerHeader onClickComing={onClickComing} />
      <HowToFeasttt />
      <Download />
      <DineOut />
      <div ref={ref}>
        <GetNotified isIn="customers" />
      </div>
    </CustomersContainer>
  );
}

export default Customers;
