/* eslint-disable react-hooks/exhaustive-deps */
import Download from "containers/Customers/Download";
import React, { useRef } from "react";
import Features from "./Features";
import GetNotified from "../../components/sections/GetNotified";
import HowToFeasttt from "./HowToFeasttt";
import RestaurantHeader from "./RestaurantHeader";
import SectionSlider from "./SectionSlider";
import { RestaurantsContainer } from "./style";

function Restaurants() {
  const ref = useRef(null);

  const onClickLearn = () => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <RestaurantsContainer>
      <RestaurantHeader onClickLearn={onClickLearn} />
      <div ref={ref}>
        <SectionSlider />
      </div>
      <HowToFeasttt />
      <Download />
      <Features />
      <GetNotified isIn="restaurants" />
    </RestaurantsContainer>
  );
}

export default Restaurants;
