import React from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";
import { FooterContainer } from "./style";
import { colors } from "theme";
import { links_home, links_pages } from "service";
import { Space } from "antd";
import moment from "moment";

function Footer() {
  const location = useLocation();

  return (
    <FooterContainer>
      <Container>
        <div className="footer-row links">
          <div className="group-link">
            {links_pages.map((_link, _i) => (
              <Link key={_i} to={_link.link} className="navbar-link">
                {_link.title}
              </Link>
            ))}
          </div>
          <div className="btns-switch">
            {links_home.map((_link, _i) => (
              <Link
                key={_i}
                to={_link.link}
                className={`${
                  _link.link === location.pathname && "active"
                } navbar-link`}
              >
                {_link.title}
              </Link>
            ))}
          </div>
        </div>
        <div className="footer-row">
          <p className="mb-0">© {moment().format("YYYY")} Foodie Ave Inc</p>
          <Space size={30} className="group-social">
            <a
              href="https://www.instagram.com/feastttapp/"
              className="soc-link"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaInstagram
                size={24}
                color={colors.white5}
                className="icon-social"
              />
            </a>
            <a
              href="https://twitter.com/feastttapp"
              className="soc-link"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaTwitter
                size={24}
                color={colors.white5}
                className="icon-social"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/feasttt"
              className="soc-link"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaFacebook
                size={24}
                color={colors.white5}
                className="icon-social"
              />
            </a>
          </Space>
        </div>
      </Container>
    </FooterContainer>
  );
}

export default Footer;
