import { Map, MapMobile } from "assets/images/restaurants";
import styled from "styled-components";
import { colors, mixins } from "theme";

export const ContactInfoContainer = styled.div`
  width: 100%;
  height: 527px;
  background: linear-gradient(
    316deg,
    rgba(58, 60, 64, 1) 7%,
    rgba(24, 24, 24, 1) 56%
  );
  .contact-info-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    .contact-info-left {
      width: 60%;
      background: url(${Map});
      background-size: 100% 100%;
    }
    .contact-info-right {
      width: 40%;
      height: 100%;
      flex: 1 1 50%;
      padding-left: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .contact-item {
        margin-bottom: 25px;
        color: #fff;
        .upper {
          margin: 0;
          margin-bottom: 10px;
          font-size: 18px !important;
          i {
            margin-right: 10px;
            text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.55);
          }
          span {
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .bottom {
          margin: 0;
          font-size: 18px !important;
          font-weight: 500;
          color: ${colors.white};
        }
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    height: unset;
    .contact-info-wrapper {
      flex-direction: column-reverse;
      .contact-info-left {
        width: 100%;
        height: 376px;
        background: url(${MapMobile});
        background-size: 100% 100%;
      }
      .contact-info-right {
        width: 100%;
        padding: 80px 0;
        padding-left: 0px;
        align-items: center;
        .contact-item {
          p {
            text-align: center;
          }
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
