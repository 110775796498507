import { Form, Input } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import MyPhoneInput from "components/Elements/MyPhoneInput";
import React, { useRef, useState } from "react";
import { Button, Tab } from "react-bootstrap";
import { recaptchasitekey, showNotification } from "service";
import { GetNotifiedFormContainer } from "./style";

function GetNotifiedForm({ onChange = () => {} }) {
  const recaptchaRefEmail = useRef();
  const recaptchaRefPhone = useRef();
  const [formEmail] = Form.useForm();
  const [formPhone] = Form.useForm();
  const [selectedTab, setSelectedTab] = useState("mobile");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [valReCaptchaEmail, setValReCaptchaEmail] = useState("");
  const [valReCaptchaPhone, setValReCaptchaPhone] = useState("");

  const onChangePhoneNumber = (value) => {
    setPhoneNumber(value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onVerifyCaptchaEmail = (value) => {
    setValReCaptchaEmail(value);
  };
  const onVerifyCaptchaPhone = (value) => {
    setValReCaptchaPhone(value);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    showNotification("error", errorFields[0].errors[0]);
  };

  const onFinishEmail = async (values) => {
    if (!valReCaptchaEmail) {
      showNotification("error", "Please, verify captcha.");
      return;
    }
    const formData = new FormData();
    formData.append("emailvalue", email);
    formData.append("contactType", "email");
    formData.append("captchaResponse", valReCaptchaEmail);
    const response = await fetch("https://code-room.com/subscribe2.php", {
      method: "POST",
      body: formData,
    });
    const result = await response.json();
    if (result && result.status === "ok") {
      showNotification("success", "Thanks, we’ll let you know when we launch!");
    } else {
      showNotification("error", "Error occurred. Please try again later.");
    }
    recaptchaRefEmail.current.reset();
    formEmail.resetFields();
  };
  const onFinishPhone = async (values) => {
    if (!valReCaptchaPhone) {
      showNotification("error", "Please, verify captcha.");
      return;
    }
    const formData = new FormData();
    formData.append("phvalue", phoneNumber);
    formData.append("contactType", "phone");
    formData.append("captchaResponse", valReCaptchaPhone);
    const response = await fetch("https://code-room.com/subscribe2.php", {
      method: "POST",
      body: formData,
    });
    const result = await response.json();
    if (result && result.status === "ok") {
      showNotification("success", "Thanks, we’ll let you know when we launch!");
    } else {
      showNotification("error", "Error occurred. Please try again later.");
    }
    recaptchaRefPhone.current.reset();
    formPhone.resetFields();
  };

  return (
    <GetNotifiedFormContainer className='get-notified-form'>
      <h5 className="comming-soon text-bold text-red text-uppercase">
        Coming Soon
      </h5>
      <h1 className="text-red">
        Get notified
        <br />
        at launch.
      </h1>
      <Tab.Container
        id="tabs-bootstrap"
        defaultActiveKey="mobile"
        activeKey={selectedTab}
      >
        <div className="tab-switcher-container">
          <div
            className={`tab-switcher-item ${
              selectedTab === "mobile" ? "active" : ""
            }`}
            onClick={() => setSelectedTab("mobile")}
          >
            Mobile
          </div>
          <div
            className={`tab-switcher-item ${
              selectedTab === "email" ? "active" : ""
            }`}
            onClick={() => setSelectedTab("email")}
          >
            Email
          </div>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="mobile" className="pane-mobile">
            <h6 className="text-bold content-title">
              We can text you the link.
            </h6>
            <Form
              className="notified-form"
              layout="vertical"
              form={formPhone}
              onFinish={onFinishPhone}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Required Field!",
                  },
                ]}
                className='notified-form-input'
                hasFeedback
              >
                <MyPhoneInput
                  className="writable-input-phone"
                  placeholder="Enter your mobile number"
                  defaultCountry="US"
                  onChange={onChangePhoneNumber}
                />
              </Form.Item>
              <ReCAPTCHA
                ref={recaptchaRefPhone}
                sitekey={recaptchasitekey}
                onChange={onVerifyCaptchaPhone}
                onExpired={() => setValReCaptchaPhone("")}
              />
              <Form.Item>
                <Button type="submit" className="btn-red w-100 btn-submit">
                  Get notified
                </Button>
              </Form.Item>
            </Form>
          </Tab.Pane>
          <Tab.Pane eventKey="email" className="pane-email">
            <h6 className="text-bold content-title">
              We can email you the link.
            </h6>
            <Form
              className="notified-form"
              layout="vertical"
              form={formEmail}
              onFinish={onFinishEmail}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Required Field!",
                  },
                  {
                    type: "email",
                    message: "Invalid Email!",
                  },
                ]}
                className='notified-form-input'
                hasFeedback
              >
                <Input
                  className="writable-input-email"
                  placeholder="Enter your email address"
                  onChange={onChangeEmail}
                />
              </Form.Item>
              <ReCAPTCHA
                ref={recaptchaRefEmail}
                sitekey={recaptchasitekey}
                onChange={onVerifyCaptchaEmail}
                onExpired={() => setValReCaptchaEmail("")}
              />
              <Form.Item>
                <Button type="submit" className="btn-red w-100 btn-submit">
                Coming Soon
                </Button>
              </Form.Item>
            </Form>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </GetNotifiedFormContainer>
  );
}

export default GetNotifiedForm;
