import styled from "styled-components";
import { colors, mixins } from "theme";

export const BlogContainer = styled.header`
  position: relative;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  .blog-overlay {
    padding-top: 100px;
    padding-bottom: 100px;
    .container {
      .all-posts {
        font-size: 32px !important;
        color: #989898;
        font-weight: 600;
        margin-top: 100px;
        margin-bottom: 40px;
      }
    }
  }
  @media (max-width: ${mixins.md}px) {
    .blog-overlay {
      padding-top: 0;
      .container.featured {
        padding: 0;
      }
      .container {
        .all-posts {
          margin-top: 60px;
        }
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;

export const FeaturedContainer = styled.div`
  background: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  .featured-content {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    top: 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 590px;
    background-repeat: no-repeat;
    .featured-mark {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 21.5px;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      width: 151px;
      height: 43px;
    }
    .featured-description {
      color: ${colors.white};
    }
  }
  @media (max-width: ${mixins.md}px) {
    .featured-content {
      min-height: 400px;
      .featured-description {
        font-size: 36px !important;
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;

export const BlogCardContainer = styled.div`
  .blog-card-img {
    height: 200px;
    width: 100%;
    background: url(${(props) => props.blogImg});
    background-size: cover;
    background-repeat: no-repeat;
  }
  h3 {
    font-size: 28px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.67px;
    margin-top: 30px;
  }
  @media (max-width: ${mixins.md}px) {
    margin-bottom: 50px;
  }
`;
