/* eslint-disable react-hooks/exhaustive-deps */
import { CareersHero, CareersHeroMobile } from "assets/images";
import React from "react";
import { Container, Image } from "react-bootstrap";
import { mixins } from "theme";
import useMediaQuery from "use-mediaquery";
import { CareersHeaderContainer } from "./style";

function CareersHeader() {
  const isMobile = useMediaQuery(`(max-width: ${mixins.md}px)`);

  return (
    <CareersHeaderContainer>
      <Image
        src={isMobile ? CareersHeroMobile : CareersHero}
        className="hero-img"
      />
      <div className="content-wrapper">
        <Container>
          <div className="header-content">
            <h1 className="text-white">Careers</h1>
            <h5 className="text-white">
              Join the fast-growing <br className='d-md-none' /> Feasttt team.
            </h5>
          </div>
        </Container>
      </div>
    </CareersHeaderContainer>
  );
}

export default CareersHeader;
