import styled from "styled-components";
import { mixins } from "theme";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export const MyPhoneInputContainer = styled(PhoneInput)`
  width: 100%;
  border-bottom: solid 2px #000;
  height: 40px;
  input {
    border: none;
    padding-bottom: 0;
    background-color: transparent;
  }
  input:focus {
    outline: none;
  }
  @media (max-width: ${mixins.md}px) {
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
export const MyPhoneFormInputContainer = styled(PhoneInput)`
  width: 100%;
  height: 50px;
  padding: 10px 10px 10px 18px;
  border-radius: 8px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  input {
    border: none;
    background-color: #fafafa;
  }
  input:focus {
    outline: none;
  }
  @media (max-width: ${mixins.md}px) {
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
