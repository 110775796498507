import styled from "styled-components";
import { colors, mixins } from "theme";

export const GetNotifiedFormContainer = styled.div`
  h5.comming-soon {
    font-weight: 600;
  }
  h1 {
    font-size: 90px !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: -2.16px;
  }
  .tab-switcher-container {
    color: #fff;
    width: 350px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 32px 0;
    padding: 5px;
    .tab-switcher-item {
      flex: 1 1 50%;
      display: flex;
      justify-content: center;
      padding: 8px 0;
      cursor: pointer;
      font-size: 20px !important;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.48px;
      text-align: center;
      color: ${colors.dark5};
      &.active {
        color: ${colors.red};
        border-radius: 20px;
        background-color: ${colors.white};
      }
    }
  }
  .tab-content {
    width: 350px;
    .notified-form {
      .notified-form-input {
        margin-bottom: 45px;
      }
    }
    .content-title {
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.48px;
    }
    .writable-input-email {
      width: 100%;
      border: none;
      border-bottom: solid 2px #000;
      height: 40px;
      background-color: transparent;
      &:active, &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    .btn-submit {
      height: 50px;
      margin-top: 45px;
    }
  }
  @media (max-width: ${mixins.md}px) {
    h5.comming-soon {
      font-size: 20px !important;
      font-weight: 600;
    }
    h1 {
      font-size: 45px !important;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: -1.08px;
      margin-bottom: 40px;
    }
    .tab-switcher-container {
      color: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 25px;
      background-color: rgba(0, 0, 0, 0.1);
      margin: 20px 0;
      padding: 5px;
      .tab-switcher-item {
        flex: 1 1 50%;
        display: flex;
        justify-content: center;
        padding: 8px 0;
        cursor: pointer;
        font-size: 20px !important;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.48px;
        text-align: center;
        color: ${colors.dark5};
        &.active {
          color: ${colors.red};
          border-radius: 20px;
          background-color: ${colors.white};
        }
      }
    }
    .tab-content {
      width: 100%;
      .btn-submit {
        margin-top: 45px;
      }
    }
  }
  @media (max-width: ${mixins.sm}px) {
  }
`;
